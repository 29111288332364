import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import CloudSelectorField from 'shared/components/formik/CloudSelectorField';
import { useProfileFieldOptions } from 'guideProfile/hooks/queries';
import SwatForm from 'shared/components/SwatForm/SwatForm';

const validationSchema = Yup.object().shape({
  keyword: Yup.array().min(2, 'Please select at least two options'),
});

const initialValues = { keyword: [] };

export default function KeywordForm() {
  const fieldOptions = useProfileFieldOptions('keyword').data;
  return (
    <SwatForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      label="SEO Keyword"
      marginVariant="noMargin"
    >
      <Field
        name="keyword"
        size="small"
        component={CloudSelectorField}
        items={fieldOptions}
      />
    </SwatForm>
  );
}
