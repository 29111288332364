import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/Button/Button';
import ImageModal from 'shared/components/ImageModal/ImageModal';
import { formikFormPropTypes } from 'shared/components/formik/propTypes';
import CertificationFields from 'certifications/components/CertificationFields/CertificationFields';

const Certifications = ({
  field: { value: fullCerts },
  form: { setFieldValue, setFieldError, setFieldTouched },
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleAddCert = () => {
    setFieldValue(
      'fullCerts',
      fullCerts.concat([{ __typename: 'Cert', id: '', name: '', url: '' }])
    );
  };

  const certsMap = fullCerts.map((item, index) => (
    <CertificationFields
      key={`certification-${index}`} // eslint-disable-line react/no-array-index-key
      index={index}
      setFieldValue={setFieldValue}
      setFieldError={setFieldError}
      setFieldTouched={setFieldTouched}
      name={item.name}
      url={item.url}
    />
  ));

  return (
    <>
      {certsMap}
      <Button size="small" color="secondary" onClick={handleAddCert}>
        Add Certification
      </Button>
      {isModalOpen && <ImageModal onClose={handleModalClose} />}
    </>
  );
};

Certifications.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string, // certifications entered by admin might not have uploaded cert?
      }).isRequired
    ).isRequired,
  }).isRequired,
  form: formikFormPropTypes.isRequired,
};

export default Certifications;
