import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import CloudSelectorField from 'shared/components/formik/CloudSelectorField';
import { useProfileFieldOptions } from 'guideProfile/hooks/queries';
import SwatForm from 'shared/components/SwatForm/SwatForm';

const validationSchema = Yup.object().shape({
  diversity: Yup.array().min(1, 'Please select at least one option'),
});

const initialValues = { diversity: [] };

export default function DiversityForm() {
  const fieldOptions = useProfileFieldOptions('diversity').data;
  return (
    <SwatForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      label="diversity"
      marginVariant="noMargin"
    >
      <Field
        name="diversity"
        size="small"
        component={CloudSelectorField}
        items={fieldOptions}
      />
    </SwatForm>
  );
}
