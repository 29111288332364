import { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebaseConfig';
import get from 'lodash/get';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      jwt
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      name
      code_of_conduct
      country
    }
  }
`;

export function useLogin(onSuccessRouteTo) {
  const history = useHistory();
  const [firebaseError, setFireBaseError] = useState();
  const [loading, setLoading] = useState(false);
  const [mutate, { error: mutationError }] = useMutation(LOGIN);
  const { refetch: refetchCurrentUser } = useQuery(GET_CURRENT_USER, {
    // Do not initiate the query on mount. Only here for re-fetching
    skip: true,
  });

  const login = useCallback(
    async (email, password) => {
      // Track loading state separate from the mutation loading state to encapsulate
      // the firebase loading as well.
      setLoading(true);

      let res;
      try {
        res = await mutate({
          variables: {
            email,
            password,
          },
        });
      } catch (err) {
        // The error is contained in the mutationResult.
        // Do not continue with firebase login.
        setLoading(false);
        return;
      }

      try {
        await firebase.auth().signInWithCustomToken(get(res, 'data.login.jwt'));
        setFireBaseError();

        // Refetch the current user now there has been a new login.
        // This is to update any components that are observing the current user query.
        // Must happen after firebase login (and not as a refetch query to the mutation),
        // so that the new auth header is sent in the request.
        await refetchCurrentUser();
      } catch (err) {
        // Any error occurred logging in to firebase. Firebase is down?
        console.error('Failed to login with firebase: ', err);
        setFireBaseError(err);
      }

      setLoading(false);
      if (onSuccessRouteTo) {
        // Use replace so the user can't go back to the login page
        history.replace(onSuccessRouteTo);
      }
    },
    [history, mutate, onSuccessRouteTo, refetchCurrentUser]
  );

  // Combine the errors
  const result = useMemo(
    () => ({
      loading,
      error: mutationError || firebaseError,
    }),
    [firebaseError, mutationError, loading]
  );

  return [login, result];
}

export function useCurrentUser() {
  const query = useQuery(GET_CURRENT_USER);
  return {
    ...query,
    data: get(query, 'data.currentUser'),
  };
}
