import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import { rem, color } from 'theme/lib';
import media from 'theme/media';

export const Layout = styled(SplitLayout)`
  ${media.up('medium')} {
    padding-right: ${rem(47)};
  }
  ${media.up('huge')} {
    padding-left: ${rem(47)};
  }
`;

export const Main = styled(SplitLayout.Main)`
  margin-top: ${rem(24)};
  padding-bottom: ${rem(100)};
  padding-top: ${rem(24)};
`;

export const StyledNavLink = styled(NavLink)`
  display: inline-block;
  text-align: center;
  border-radius: 0;
  min-width: ${rem(150)};
  margin-right: ${rem(25)};
  padding: ${rem(5)} ${rem(40)};
  color: ${color('black')};
  text-decoration: none;
  background-color: ${color('offWhite')};
  font-size: ${rem(24)};

  &.is-active {
    background: ${color('base.sphereBlue')};
    color: ${color('white')};
  }
`;

export const ListSelector = styled.div`
  margin: ${rem(20)} 0;
`;

export const InputWrapper = styled.div`
  display: flex;
  background-color: ${color('white')};
  border-bottom: 2px solid ${color('form.inputBorder')};
  margin: ${rem(60)} 0;
  max-width: ${rem(500)};
  :focus-within {
    border-color: ${color('blacks.100')};
  }
`;
export const StyledInput = styled.input`
  flex: 1;
  display: block;
  border: 0;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25;
  background-color: transparent;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    font-weight: normal;
    color: ${color('form.inputPlaceholder')};
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  padding-left: ${rem(10)};
  width: ${rem(36)};
  height: ${rem(36)};
  flex: 0 0 ${rem(36)};
`;
