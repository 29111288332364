import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pick from 'lodash/pick';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { removeNullElements } from 'shared/utils/object';
import { useGuideById, useUpdateGuideById } from 'guideProfile/hooks/queries';
import ErrorMessage from 'shared/components/ErrorMessage/ErrorMessage';
import { FormWrapper, FormHeader, Submit, Label } from './styled';
import ExpandFieldButton from '../ExpandFieldButton/ExpandFieldButton';
/**
 * Build the initial values by merging existing data and defaults.
 * Only uses the keys that were specified in the initial values.
 */
function useInitialValues(defaults, data) {
  const merged = merge(
    {},
    defaults,
    pick(removeNullElements(data, true), Object.keys(defaults))
  );

  // Deep equal memoize so that the initial values reference does not change needlessly.
  // Formik has a weird side effect of flashing validation errors if the initial values
  // change.
  const prevMerge = useRef(merged);
  if (!isEqual(prevMerge.current, merged)) {
    prevMerge.current = merged;
    return merged;
  }

  return prevMerge.current;
}

function SwatForm({
  children,
  label,
  initialValues,
  validationSchema,
  marginVariant,
  showSaveButton,
  isExpandable,
  onExpandableClick,
  onRemoveLineClick,
}) {
  const { guideId } = useParams();
  const guideData = get(useGuideById(guideId), 'data', {});
  const mergedInitialValues = useInitialValues(initialValues, guideData);
  const [updateGuide, { loading, error }] = useUpdateGuideById(guideId);

  const handleSubmit = useCallback(
    v => {
      updateGuide(v);
    },
    [updateGuide]
  );
  return (
    <FormWrapper marginVariant={marginVariant}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={mergedInitialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          const isSubmitDisabled = !dirty || !isValid || loading;

          return (
            <Form>
              <FormHeader>
                <Label>{label}</Label>
                {showSaveButton && (
                  <Submit type="submit" disabled={isSubmitDisabled}>
                    {loading ? 'Saving' : 'Save Changes'}
                  </Submit>
                )}
              </FormHeader>
              <ErrorMessage error={error} />
              {children}
            </Form>
          );
        }}
      </Formik>
      {isExpandable && (
        <div>
          <ExpandFieldButton type="button" onClick={onExpandableClick}>
            +
          </ExpandFieldButton>
          <ExpandFieldButton type="button" onClick={onRemoveLineClick}>
            -
          </ExpandFieldButton>
        </div>
      )}
    </FormWrapper>
  );
}

SwatForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object,
  /** Apply alternate styling to form wrapper. */
  marginVariant: PropTypes.oneOf(['default', 'noMargin']),
  /** Renders Save Changes btn eg: some forms are auto saved and button is not nedded. */
  showSaveButton: PropTypes.bool,
  isExpandable: PropTypes.bool,
  onExpandableClick: PropTypes.func.isRequired,
  onRemoveLineClick: PropTypes.func.isRequired,
};

SwatForm.defaultProps = {
  validationSchema: undefined,
  marginVariant: 'default',
  showSaveButton: true,
  isExpandable: false,
};

export default SwatForm;
