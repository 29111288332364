import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { StyledField } from '../styled';

function FieldArrayWrapper({ name, component, childProperty }) {
  return (
    <FieldArray
      name={name}
      render={({ form }) => {
        return (
          <>
            {form.values[name].map((item, index) => {
              const fieldName = childProperty
                ? `${name}.${index}.${childProperty}`
                : `${name}.${index}`;

              return (
                <StyledField
                  key={index} // eslint-disable-line react/no-array-index-key
                  name={fieldName}
                  type="text"
                  component={component}
                />
              );
            })}
          </>
        );
      }}
    />
  );
}

FieldArrayWrapper.defaultProps = {
  childProperty: undefined,
};

FieldArrayWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  childProperty: PropTypes.string,
  component: PropTypes.elementType.isRequired,
};

export default FieldArrayWrapper;
