/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ProgressBar from 'shared/components/ProgressBar/ProgressBar';
import media from 'theme/media';
import { color, rem } from 'theme/lib';

function DropzonePreview({ meta }) {
  const { percent } = meta;

  return (
    <StyledPreview>
      <StyledProgressBar percent={percent} fillColor="link" size="small" />
      Uploading...
    </StyledPreview>
  );
}

DropzonePreview.propTypes = {
  meta: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lastModifiedDate: PropTypes.string,
    name: PropTypes.string,
    percent: PropTypes.number,
    size: PropTypes.number,
    status: PropTypes.oneOf([
      'preparing',
      'error_file_size',
      'error_validation',
      'ready',
      'getting_upload_params',
      'error_upload_params',
      'uploading',
      'exception_upload',
      'aborted',
      'error_upload',
      'headers_received',
      'done',
      'removed',
    ]).isRequired,
    type: PropTypes.string.isRequired,
    uploadedDate: PropTypes.string,
  }).isRequired,
  fileWithMeta: PropTypes.shape({
    file: PropTypes.any.isRequired,
    meta: PropTypes.object.isRequired,
    cancel: PropTypes.func.isRequired,
    restart: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    xhr: PropTypes.any,
  }).isRequired,
};

const StyledProgressBar = styled(ProgressBar)`
  margin-bottom: ${rem(4)};
`;

const StyledPreview = styled.div`
  font-size: ${rem(14)};
  line-height: ${20 / 14};
  color: ${color('blacks.200')};
  max-width: ${rem(127)};
  margin: auto;
  text-align: center;
  padding-top: ${rem(16)};
  padding-left: ${rem(3)};
  padding-right: ${rem(3)};

  ${media.up('medium')} {
    padding-top: ${rem(0)};
  }
`;

export default DropzonePreview;
