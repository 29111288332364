import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import CloudSelectorField from 'shared/components/formik/CloudSelectorField';
import { useProfileFieldOptions } from 'guideProfile/hooks/queries';
import SwatForm from 'shared/components/SwatForm/SwatForm';

const validationSchema = Yup.object().shape({
  family_status: Yup.array().min(1, 'Please select at least one option'),
});

const initialValues = { family_status: [] };

export default function FamilyForm() {
  const fieldOptions = useProfileFieldOptions('family_status').data;
  return (
    <SwatForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      label="Family Status"
      marginVariant="noMargin"
    >
      <Field
        name="family_status"
        size="small"
        component={CloudSelectorField}
        items={fieldOptions}
      />
    </SwatForm>
  );
}
