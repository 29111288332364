import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import CloudSelectorField from 'shared/components/formik/CloudSelectorField';
import { useProfileFieldOptions } from 'guideProfile/hooks/queries';
import SwatForm from 'shared/components/SwatForm/SwatForm';

const validationSchema = Yup.object().shape({
  lifestyle: Yup.array().min(1, 'Please select at least two options'),
});

const initialValues = { lifestyle: [] };

export default function LifestyleForm() {
  const fieldOptions = useProfileFieldOptions('lifestyle').data;
  return (
    <SwatForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      label="Lifestyle"
      marginVariant="noMargin"
    >
      <Field
        name="lifestyle"
        size="small"
        component={CloudSelectorField}
        items={fieldOptions}
      />
    </SwatForm>
  );
}
