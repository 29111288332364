import React from 'react';
import TestimonialsFields from 'certifications/components/TestimonialsFields/TestimonialsFields';
import { FieldArray } from 'formik';
import SwatForm from 'shared/components/SwatForm/SwatForm';

export default function TestimonialsForm() {
  return (
    <SwatForm
      initialValues={{
        allTestimonials: [
          { first_name: '', last_initial: '', text: '' },
          { first_name: '', last_initial: '', text: '' },
          { first_name: '', last_initial: '', text: '' },
        ],
      }}
      label="Testimonials"
    >
      <FieldArray
        name="allTestimonials"
        render={({ form: { values } }) => {
          return (
            <>
              {values.allTestimonials.map((item, index) => (
                <TestimonialsFields
                  key={`allTestimonials-${index}`} // eslint-disable-line react/no-array-index-key
                  index={index}
                />
              ))}
            </>
          );
        }}
      />
    </SwatForm>
  );
}
