import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import routes from 'shared/routes';
import TabNav from 'shared/components/TabNav/TabNav';

function TabNavigation({ guideId }) {
  const navListItems = useMemo(
    () => [
      {
        title: 'Profile',
        path: routes.guideDetails.profile(guideId),
      },
      {
        title: 'Information',
        path: routes.guideDetails.information(guideId),
      },
      {
        title: 'Algorithm Information',
        path: routes.guideDetails.algorithm(guideId),
      },
      {
        title: 'Stats',
        path: routes.guideDetails.stats(guideId),
      },
    ],
    [guideId]
  );

  return <TabNav navListItems={navListItems} replace />;
}

TabNavigation.propTypes = {
  guideId: PropTypes.string.isRequired,
};

export default TabNavigation;
