import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { ApolloLink } from 'apollo-link';
import AuthorizationLink from './AuthorizationLink';
import MockBackendLink, { mockResolvers } from './mocks/MockBackendLink';

const defaults = {};

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

// Handle authorization requests to the graphql endpoint
const authorizationLink = new AuthorizationLink();

// Mock backend queries that are not fully implemented yet
const mockBackendLink = new MockBackendLink();

// Retry all network errors
const retryLink = new RetryLink({
  delay: {
    max: process.env.REACT_APP_MAX_RETRY_DELAY,
  },
  attempts: {
    max: process.env.REACT_APP_MAX_RETRY_ATTEMPTS,
    retryIf: error => {
      if (!error) {
        return false;
      }

      // Do not retry requests that have a 4XX error. They are typically client
      // errors and will not resolve after being retried.
      if (
        error.statusCode &&
        error.statusCode >= 400 &&
        error.statusCode < 500
      ) {
        return false;
      }

      return true;
    },
  },
});

// Log any GraphQL Errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const cache = new InMemoryCache();
cache.writeData({
  data: {
    ...defaults,
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([
    /**
     * Be careful with the ordering on these links. ErrorLink should be first and
     * HttpLink should be last. The others may or may not have a specific order.
     */
    errorLink,
    retryLink,
    authorizationLink,
    mockBackendLink, // Needs to be before HttpLink
    httpLink,
  ]),
  cache,
  resolvers: [mockResolvers],
});

// Mock backend link needs an instance of the Apollo client to run the
// client side resolvers
mockBackendLink.apolloClient = client;

export default client;
