/* eslint-disable no-console */
/* eslint-disable camelcase */
import { ApolloLink, Observable } from 'apollo-link';
import { STATUS_SUBMITTED } from 'guideProfile/constants';
import { profile, currentUser, mockUser } from './mockData';

// List of operations that are implemented by the mock resolvers
const mockedOperations = [
  // 'GetProfile',
  // 'GetGuideInfo', // TODO: leaving this in here in case API breaks
  // 'UpdateProfileField',
  // 'GetCurrentUser', // Remove once we implement the sign in pages
  // 'SubmitApplication',
  // 'CancelApplication', // Useful for testing
  // 'PerformAction',
];

export const mockResolvers = {
  Query: {
    // guide: () => {
    //   return {
    //     ...profile,
    //   };
    // },
    guideInfo: (_, { id }) => {
      return {
        ...profile,
        __typename: 'Sphere',
        id,
        header_image: `https://images-cdn.sphereishere.com/${
          id === '1678' ? '633' : id
        }.jpg`,
        first_name: `${profile.first_name} #${id}`,
      };
    },
    currentUser: () => mockUser(),
  },
  Mutation: {
    updateProfileField: (_, args) => {
      let { value } = args;
      try {
        value = JSON.parse(args.value);
      } catch (err) {
        // Probably a string value. just store the whole string
      }

      if (args.field === 'country') {
        // Country field is on the user object instead
        currentUser.country = value;
      } else {
        profile[args.field] = value;
      }
      profile.updated_at = new Date().toISOString();
      return true;
    },
    submitApplication: () => {
      profile.application_status = STATUS_SUBMITTED;
      profile.updated_at = new Date().toISOString();
      return true;
    },
    cancelApplication: (_, { reason, detailed_explanation }) => {
      console.log('Canceling application. ', { reason, detailed_explanation });
      return true;
    },
    actionApplication: (_, args) => {
      console.log('Action application: ', args);
      return true;
    },
  },
};

/**
 * Link for redirecting queries to a client resolver while we wait for the to
 * be implemented on the backend. Once the backend is fully up to date, we can
 * remove this.
 *
 * This allows uo to have queries without '@client' to be implemented by the
 * mock resolvers. If we use '@client' instead, the test cases that mock query
 * responses will fail.
 */
export default class MockBackendLink extends ApolloLink {
  // eslint-disable-next-line class-methods-use-this
  request(operation, forward) {
    return new Observable(observer => {
      if (mockedOperations.includes(operation.operationName)) {
        const timer = setTimeout(() => {
          /**
           * Run the client resolvers regardless of whether or not there is an
           * '@client' directive specified. Mock the network response as an empty
           * object so that everything get defined by the client side resolvers.
           */
          this.apolloClient.localState
            .runResolvers({
              document: operation.query,
              context: operation.getContext(),
              variables: operation.variables,
              remoteResult: {
                data: {},
              },
            })
            .then(data => {
              observer.next(data);
              observer.complete();
            });
        }, 300); // Artificial delay

        // Return the unsubscribe function
        return () => {
          clearTimeout(timer);
        };
      }

      // Operation is not mocked out, so allow it to pass to the backend
      const handle = forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      });
      return () => handle.unsubscribe();
    });
  }
}
