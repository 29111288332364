import React, { useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import routes from 'shared/routes';
import Button from 'shared/components/Button/Button';
import ErrorMessage from 'shared/components/ErrorMessage/ErrorMessage';
import TextField from 'shared/components/formik/TextField/TextField';
import { useLogin } from '../../hooks/queries';

const MIN_PASSWORD_LENGTH = 8;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Invalid email address'),

  /**
   * NOTE: Normally wouldn't put password validation here, but the backend returns
   * password validation error messages and it is nicer to have the errors associated
   * with the actual input field, instead of as a form error.
   */
  password: Yup.string()
    .required('Required')
    .min(
      MIN_PASSWORD_LENGTH,
      `Must be at least ${MIN_PASSWORD_LENGTH} characters long`
    )
    .test('no-spaces', "Can't contain any spaces", value => {
      return !/\s/.test(value);
    }),
});

const initialValues = {
  password: '',
  email: '',
};

export default function LoginForm() {
  const [login, { loading: isLoggingIn, error }] = useLogin(routes.all);
  const handleSubmit = useCallback(
    ({ email, password }) => {
      login(email, password);
    },
    [login]
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ dirty }) => {
        const isSubmitDisabled = !dirty || isLoggingIn;
        return (
          <StyledForm>
            <Field
              name="email"
              placeholder="Email Address"
              component={TextField}
              autoFocus
            />
            <StyledField
              name="password"
              placeholder="Password"
              type="password"
              component={TextField}
            />

            <StyledButton block type="submit" disabled={isSubmitDisabled}>
              Sign In
            </StyledButton>
            <ErrorMessage error={error} />
          </StyledForm>
        );
      }}
    </Formik>
  );
}

const StyledForm = styled(Form)`
  margin-top: ${rem(48)};
`;

const StyledField = styled(Field)`
  margin-bottom: ${rem(8)};
`;

const StyledButton = styled(Button)`
  margin-top: ${rem(60)};
`;
