import { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import gql from 'graphql-tag';

// 1: Get new rate

const GET_NEW_RATE = gql`
  query RecalculateCost($id: ID!) {
    recalculateCost(id: $id) # Int
  }
`;

export const useReviewGuideRate = id => {
  const reviewQuery = useQuery(GET_NEW_RATE, {
    variables: {
      id,
    },
  });

  return useMemo(() => {
    const newRate = get(reviewQuery, 'data.recalculateCost', 0) / 100;
    return {
      ...reviewQuery,
      newRate,
    };
  }, [reviewQuery]);
};

// 2: Update Rate

const UPDATE_RATE = gql`
  mutation UpdateCost($guideId: ID!, $value: Int!) {
    updateCost(guideId: $guideId, value: $value)
  }
`;

export const useUpdateGuideRate = () => {
  const [update, result] = useMutation(UPDATE_RATE);

  const updateRate = useCallback(
    (guideId, value, onSuccess) => {
      update({
        variables: {
          guideId,
          value,
        },
        refetchQueries: ['GetProfile'],
        awaitRefetchQueries: true,
      }).then(({ data: { updateCost } }) => {
        if (updateCost) {
          onSuccess();
        }
      });
    },
    [update]
  );

  return {
    updateRate,
    result,
  };
};
