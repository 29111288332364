import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { rem, color } from 'theme/lib';
import TabNavItem from './TabNavItem';

function TabNav({ className, navListItems, replace }) {
  return (
    <TabsWrapper>
      <StyledTabNav className={className}>
        {navListItems.map(item => (
          <TabNavItem
            disabled={item.disabled}
            key={item.title}
            title={item.title}
            to={item.path}
            replace={replace}
          />
        ))}
      </StyledTabNav>
      <BottomBorder />
    </TabsWrapper>
  );
}

TabNav.propTypes = {
  navListItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  replace: PropTypes.bool,
};

TabNav.defaultProps = {
  replace: false,
};

const TabsWrapper = styled.div``;

const StyledTabNav = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
`;

const BottomBorder = styled.div`
  margin-top: ${rem(13)};
  height: ${rem(1)};
  background-color: ${color('grays.100')};
`;

export default TabNav;
