/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import {
  STATUS_ACTIVE,
  STATUS_APPROVED,
  STATUS_INACTIVE,
  statusLabelMap,
} from '../constants';

export const useGuideStatus = () => {
  return useCallback(guide => {
    const { application_status: applicationStatus, approved, active } = guide;

    if (approved) {
      return active
        ? statusLabelMap[STATUS_ACTIVE]
        : statusLabelMap[STATUS_INACTIVE];
    }

    // not approved
    return applicationStatus === STATUS_APPROVED
      ? statusLabelMap[STATUS_APPROVED]
      : statusLabelMap[applicationStatus] || applicationStatus;
  }, []);
};
