import React from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { FieldLabel } from 'shared/components/formik/styled';
import { COUNTRY_CODE_CANADA } from 'shared/constants';
import { ReactComponent as FacebookIcon } from 'assets/svg/facebook.svg';
import { ReactComponent as LinkedInIcon } from 'assets/svg/linkedin.svg';
import { ReactComponent as InstagramIcon } from 'assets/svg/instagram.svg';
import { ReactComponent as WebsiteIcon } from 'assets/svg/website.svg';
import SwatForm from 'shared/components/SwatForm/SwatForm';
import { countries, states, provinces } from '../contactInfoContent';

import {
  ColumnGrid,
  ColumnInputField,
  ColumnPhoneField,
  SuiteField,
  ColumnSelectField,
  BirthdateInputField,
  MailingAddressContainer,
  MailingAddressField,
} from '../styled';

function AddressFields() {
  const { values, setFieldValue } = useFormikContext();
  const isCanadianAddress =
    values.country === COUNTRY_CODE_CANADA || values.country === '';
  const handleCountryChange = e => {
    setFieldValue('country', e.target.value);
    // Resets provinces/states value when country changes
    setFieldValue('mailing_address.stprv', '');
  };
  return (
    <>
      <ColumnGrid>
        <Field
          name="country"
          component={ColumnSelectField}
          onChange={handleCountryChange}
          label="Country"
          options={countries}
          labelVariant="noMargin"
        />
        <Field
          name="mailing_address.stprv"
          component={ColumnSelectField}
          label={isCanadianAddress ? 'Province' : 'State'}
          options={isCanadianAddress ? provinces : states}
          labelVariant="noMargin"
        />
      </ColumnGrid>
      <ColumnGrid>
        <Field
          name="mailing_address.city"
          type="text"
          component={ColumnInputField}
          label="City"
          labelVariant="noMargin"
        />
        <Field
          name="mailing_address.postal_code"
          type="text"
          component={ColumnInputField}
          label={isCanadianAddress ? 'Postal Code' : 'Zip Code'}
          labelVariant="noMargin"
        />
      </ColumnGrid>
    </>
  );
}

const initialValues = {
  first_name: '',
  last_name: '',
  birthdate: '',
  phone: {
    mobile: '',
    work: '',
  },
  mailing_address: {
    address_1: '',
    address_2: '',
    postal_code: '',
    city: '',
    stprv: '',
  },
  country: '',
  socials: {
    facebook: '',
    linkedin: '',
    instagram: '',
    website: '',
  },
};

const ContactForm = () => (
  <SwatForm label="Contact Information" initialValues={initialValues}>
    <ColumnGrid>
      <Field
        name="first_name"
        type="text"
        component={ColumnInputField}
        label="First Name"
        labelVariant="noMargin"
      />
      <Field
        name="last_name"
        type="text"
        component={ColumnInputField}
        label="Last Name"
        labelVariant="noMargin"
      />
    </ColumnGrid>
    <ColumnGrid>
      <Field
        name="birthdate"
        type="date"
        label="Birth date"
        component={BirthdateInputField}
        labelVariant="noMargin"
      />
    </ColumnGrid>
    <ColumnGrid>
      <Field
        name="phone.mobile"
        type="text"
        component={ColumnPhoneField}
        label="Mobile phone number"
        labelVariant="noMargin"
      />
      <Field
        name="phone.work"
        type="text"
        component={ColumnPhoneField}
        label="Work phone number"
        labelVariant="noMargin"
      />
      <MailingAddressContainer>
        <Field
          name="mailing_address.address_1"
          type="text"
          component={MailingAddressField}
          label="Mailing Address"
          labelVariant="noMargin"
        />
        <Field
          name="mailing_address.address_2"
          type="text"
          component={SuiteField}
          label="Apartment/Suite/Other"
          labelVariant="noMargin"
        />
      </MailingAddressContainer>
    </ColumnGrid>
    <AddressFields />
    <FieldLabel>Social Accounts</FieldLabel>
    <ColumnGrid />
    <ColumnGrid>
      <FieldArray
        name="socials"
        render={() => (
          <>
            <Field
              name="socials.facebook"
              type="text"
              component={ColumnInputField}
              label="Facebook Business"
              borderSize={2}
              paddingSize="large"
              adornmentEndAlign="center"
              labelVariant="noMargin"
              renderAdornmentEnd={() => <FacebookIcon />}
            />
            <Field
              name="socials.linkedin"
              type="text"
              component={ColumnInputField}
              label="LinkedIn"
              borderSize={2}
              paddingSize="large"
              adornmentEndAlign="center"
              renderAdornmentEnd={() => <LinkedInIcon />}
              labelVariant="noMargin"
            />
            <Field
              name="socials.instagram"
              type="text"
              component={ColumnInputField}
              label="Instagram"
              borderSize={2}
              paddingSize="large"
              adornmentEndAlign="center"
              renderAdornmentEnd={() => <InstagramIcon />}
              labelVariant="noMargin"
            />
            <Field
              name="socials.website"
              type="text"
              component={ColumnInputField}
              label="Website"
              borderSize={2}
              paddingSize="large"
              adornmentEndAlign="center"
              renderAdornmentEnd={() => <WebsiteIcon />}
              labelVariant="noMargin"
            />
          </>
        )}
      />
    </ColumnGrid>
  </SwatForm>
);

export default ContactForm;
