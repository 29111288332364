// Allow props spreading because this a wrapper component.
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { rem, color } from 'theme/lib';
import { NavLink } from 'react-router-dom';

function TabNavItem({ className, title, disabled, ...navLinkProps }) {
  return (
    <StyledListItem className={className} disabled={disabled}>
      <StyledNavLink {...navLinkProps}>{title}</StyledNavLink>
    </StyledListItem>
  );
}

TabNavItem.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

TabNavItem.defaultProps = {
  title: '',
  disabled: false,
};

const StyledListItem = styled.li`
  font-weight: 500;
  padding-right: ${rem(30)};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}
`;

const StyledNavLink = styled(NavLink)`
  position: relative;
  text-decoration: none;
  color: ${color('blacks.200')};

  &:hover {
    text-decoration: none;
    color: ${color('black')};
  }

  &.active {
    /* Draw bottom bar */
    &::after {
      position: absolute;
      bottom: ${rem(-15)};
      display: block;
      content: '';
      width: 100%;
      height: ${rem(1)};
      background-color: ${color('blacks.200')};
    }
  }
`;

export default TabNavItem;
