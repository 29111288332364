/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import routes from 'shared/routes';
import Table from 'shared/components/Table/Table';
import { TextLinkButton } from 'shared/components/styled/buttons';
import { useGuideStatus } from '../../hooks/useGuideStatus';

const GuideList = ({ data, fields }) => {
  const [sortBy, setSortBy] = useState({ value: 'name', asc: true });
  const history = useHistory();
  const current = history.location.pathname.substr(1);
  const getGuideStatus = useGuideStatus();

  const handleRowClick = guideId => {
    history.push(routes.guideDetails.profile(guideId));
  };

  const sort = useCallback(
    (a, b) => {
      // make sure it's not undefined
      if (a[sortBy.value]) {
        if (sortBy.value === 'updated_at') {
          return sortBy.asc
            ? new Date(b[sortBy.value]) - new Date(a[sortBy.value])
            : new Date(a[sortBy.value]) - new Date(b[sortBy.value]);
        }

        const c = a[sortBy.value].toUpperCase();
        const d = b[sortBy.value].toUpperCase();
        let val = 0;

        if (c < d) {
          val = sortBy.asc ? -1 : 1;
        } else if (c > d) {
          val = sortBy.asc ? 1 : -1;
        }

        return val;
      }
      return a - b;
    },
    [sortBy.value, sortBy.asc]
  );

  const guides = useMemo(() => {
    switch (current) {
      case 'queue':
        return data.filter(guide => !guide.active).sort(sort);
      case 'active':
        return data.filter(guide => guide.active).sort(sort);
      case 'all':
      default:
        return data.sort(sort);
    }
  }, [current, data, sort]);

  return (
    <Table>
      <thead>
        <Table.TRHEADER>
          {fields.map(field => (
            <Table.TH key={field.key} style={field.headerStyle}>
              <TextLinkButton
                style={{ color: 'black' }}
                onClick={() =>
                  setSortBy(prev => ({
                    value: field.key,
                    asc: !prev.asc,
                  }))
                }
              >
                {field.label}
                {sortBy.value === field.key && (
                  <>
                    &nbsp;
                    {sortBy.asc ? '△' : '▽'}
                  </>
                )}
              </TextLinkButton>
            </Table.TH>
          ))}
        </Table.TRHEADER>
      </thead>
      <tbody>
        {guides.map(row => (
          <Table.TR key={row.id} onClick={() => handleRowClick(row.id)}>
            {fields.map(field => {
              if (field.component) {
                return (
                  <Table.TD style={field.cellStyle} key={field.key}>
                    <field.component row={row} />
                  </Table.TD>
                );
              }

              const value = () => {
                // ugh...
                if (
                  row.approved &&
                  field.formatter &&
                  field.key !== 'updated_at'
                ) {
                  return getGuideStatus(row);
                }
                return field.formatter
                  ? field.formatter(row[field.key])
                  : row[field.key];
              };
              return (
                <Table.TD style={field.cellStyle} key={field.key}>
                  {value() || null}
                </Table.TD>
              );
            })}
          </Table.TR>
        ))}
      </tbody>
    </Table>
  );
};

GuideList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      formatter: PropTypes.func,
    })
  ).isRequired,
};

export default GuideList;
