const guideDetailsBase = (guideId = ':guideId') => `/guide/${guideId}`;

export default {
  login: '/login',
  error: '/error',
  queue: `/queue`,
  all: `/all`,
  active: `/active`,
  guideDetails: {
    base: guideDetailsBase,
    profile: guideId => `${guideDetailsBase(guideId)}/profile`,
    information: guideId => `${guideDetailsBase(guideId)}/information`,
    algorithm: guideId => `${guideDetailsBase(guideId)}/algorithm`,
    stats: guideId => `${guideDetailsBase(guideId)}/stats`,
  },
};
