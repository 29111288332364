import React from 'react';
import PropTypes from 'prop-types';
import { StyledTable, TH, TD, TR, TRHEADER } from './styled';

const Table = ({ className, children }) => {
  return (
    <StyledTable
      className={className}
      border={0}
      cellSpacing={0}
      cellPadding={0}
    >
      {children}
    </StyledTable>
  );
};

Table.TH = TH;
Table.TD = TD;
Table.TR = TR;
Table.TRHEADER = TRHEADER;

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Table;
