import React, { useCallback, useState } from 'react';
import get from 'lodash/get';
import Button from 'shared/components/Button/Button';
import MobileModal from 'shared/components/MobileModal/MobileModal';
import Switch from 'shared/components/Switch';
import { useParams } from 'react-router-dom';
import { COUNTRY_CODE_AMERICA } from 'shared/constants';
import { removeNullElements } from 'shared/utils/object';
import { useGuideById, useUpdateGuideById } from 'guideProfile/hooks/queries';
import ReviewGuideRate from './ReviewGuideRate';
import {
  Layout,
  StatsContainer,
  Label,
  StatsItem,
  PostSessionContainer,
  FeelingWrapper,
  CountWrapper,
  GuideRateContainer,
} from './styled';

const moneyFormatter = (v = 0, currency) => {
  return `$${v} ${currency}`;
};

function Stats() {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const { guideId } = useParams();
  const [updateGuide] = useUpdateGuideById(guideId);
  const guide = removeNullElements(
    get(useGuideById(guideId), 'data', {}),
    true
  );

  const currency = guide.country === COUNTRY_CODE_AMERICA ? 'USD' : 'CAD';
  const rate = get(guide, 'cost', 0) / 100;
  const stats = get(guide, 'insights', {
    matches: 0,
    completedGuidanceSessions: 0,
    totalProfileViews: 0,
  });
  const feelingSummaries = get(
    guide,
    'insights.postSessionFeelingsSummary',
    []
  ).sort((a, b) => b.count - a.count);
  const meets = get(guide, 'allSessions', []).reduce((acc, cur) => {
    const {
      seeker: { id },
      discovery,
    } = cur;
    // get all the unique seeker ids
    if (discovery && !acc.includes(id)) acc.push(id);

    return acc;
  }, []);
  const socialEngaged = get(guide, 'social_media_presence', false);
  const sphereComms = get(guide, 'sphere_comms', false);

  const toggleReviewModal = useCallback(() => {
    setShowReviewModal(!showReviewModal);
  }, [showReviewModal, setShowReviewModal]);

  return (
    <Layout>
      {showReviewModal && (
        <MobileModal bg="transparent" onClose={toggleReviewModal}>
          <ReviewGuideRate
            guideId={guideId}
            currentRate={rate}
            currency={currency}
            onClose={toggleReviewModal}
          />
        </MobileModal>
      )}
      <StatsContainer>
        <StatsItem>
          <Label>Social Media Presence</Label>
          <Switch
            id="social-engaged"
            on={socialEngaged}
            size={30}
            toggle={() =>
              updateGuide({ social_media_presence: !socialEngaged })
            }
          />
        </StatsItem>

        <StatsItem>
          <Label>Lifetime Revenue</Label>
          {moneyFormatter(stats.revenue, currency)}
        </StatsItem>

        <StatsItem>
          <Label>Next Total Payout</Label>
          {moneyFormatter(stats.nextPayment, currency)}
        </StatsItem>

        <StatsItem>
          <Label>Meets</Label>
          {meets.length}
        </StatsItem>

        <StatsItem>
          <Label>Regular Sessions</Label>
          {stats.completedGuidanceSessions}
        </StatsItem>

        <StatsItem>
          <Label>Communicates with Sphere</Label>
          <Switch
            id="sphere-comms"
            on={sphereComms}
            size={30}
            toggle={() => updateGuide({ sphere_comms: !sphereComms })}
          />
        </StatsItem>

        <StatsItem>
          <Label>Matches</Label>
          {stats.matches}
        </StatsItem>

        <StatsItem>
          <Label>Profile Views</Label>
          {stats.totalProfileViews}
        </StatsItem>

        <StatsItem style={{ flex: '0 0 15%' }}>
          <Label>Hourly Rate</Label>
          <GuideRateContainer>
            {moneyFormatter(rate, currency)}
            <Button
              onClick={toggleReviewModal}
              style={{ minWidth: 'unset' }}
              shape="pill"
              size="small"
            >
              Review
            </Button>
          </GuideRateContainer>
        </StatsItem>
        {feelingSummaries.length && (
          <PostSessionContainer>
            <FeelingWrapper>
              <Label>Post Session Ratings</Label>
              {feelingSummaries.map(item => (
                <StatsItem key={`feeling-${item.feeling}`}>
                  {item.feeling}
                </StatsItem>
              ))}
            </FeelingWrapper>
            <CountWrapper>
              <Label>Count</Label>
              {feelingSummaries.map(item => (
                <StatsItem key={`count-${item.feeling}`}>
                  {item.count}
                </StatsItem>
              ))}
            </CountWrapper>
          </PostSessionContainer>
        )}
      </StatsContainer>
    </Layout>
  );
}

export default Stats;
