import React, { useState } from 'react';
import TextField from 'shared/components/formik/TextField/TextField';
import SwatForm from 'shared/components/SwatForm/SwatForm';
import FieldArrayWrapper from './FieldArrayWrapper';

export default function ExperienceForm() {
  const [experience, setExperience] = useState(['', '', '']);

  const addExperienceLines = () => {
    if (experience.length >= 6) {
      window.alert(`Save some for the rest of us 💫`);
      return;
    }
    setExperience(prev => [...prev, '']);
  };

  const removeExpLines = () => {
    const copyExp = [...experience].slice(0, -1);
    if (experience.length <= 1) {
      window.alert(`Sorry you can't have less than 1 line 💫`);
      return;
    }
    setExperience(copyExp);
  };

  return (
    <SwatForm
      initialValues={{ experience }}
      label="Experience"
      isExpandable
      onExpandableClick={addExperienceLines}
      onRemoveLineClick={removeExpLines}
    >
      <FieldArrayWrapper name="experience" component={TextField} />
    </SwatForm>
  );
}
