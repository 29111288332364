import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { color, rem } from 'theme/lib';
import { ButtonBase } from 'shared/components/styled/buttons';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import AuthImg from 'shared/components/AuthImg/AuthImg';
import { ReactComponent as IconClose } from './close.svg';

const modalRoot = document.getElementById('modal-portal');

function ImageModal({ imageUrl, onClose }) {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const portalContainer = useRef(document.createElement('div'));
  const isPDF = useMemo(() => imageUrl && /\.pdf/g.test(imageUrl), [imageUrl]);

  useEffect(() => {
    const portalEl = portalContainer.current;
    modalRoot.appendChild(portalEl);
    return () => modalRoot.removeChild(portalEl);
  }, [portalContainer]);

  const children = useMemo(() => {
    switch (isError) {
      case true:
        return (
          <ModalContent>
            <Error>Error loading image</Error>
          </ModalContent>
        );
      default:
        return isPDF ? (
          <PDF
            title="PDF"
            src={imageUrl}
            onLoad={() => {
              setLoading(false);
            }}
            onError={() => {
              setError(true);
              setLoading(false);
            }}
          />
        ) : (
          <StyledAuthImage
            src={imageUrl}
            alt="Certification"
            onLoad={() => {
              setLoading(false);
            }}
            onError={() => {
              setError(true);
              setLoading(false);
            }}
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl, isError, isPDF, isLoading, setLoading, setError]);

  // A portal is needed because this modal is rendered within an element that is
  // transformed, which restricts fixed position children within that container.
  return createPortal(
    <Overlay onClick={onClose}>
      {isLoading && <LoadingSpinner color="#fff" showText={false} />}

      <Modal isLoading={isLoading}>
        <CloseButton onClick={onClose}>
          <IconClose />
        </CloseButton>
        <ModalBody>{children}</ModalBody>
      </Modal>
    </Overlay>,
    portalContainer.current
  );
}

ImageModal.propTypes = {
  /** URL of the protected image resource to fetch via XHR. */
  imageUrl: PropTypes.string.isRequired,
  /** Event handler to close the modal. */
  onClose: PropTypes.func.isRequired,
};

ImageModal.defaultProps = {};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.66);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(30)};
`;

const Modal = styled.div`
  position: relative;
  margin: auto;
  max-width: ${rem(1140)};
  width: 100%;
  padding: ${rem(35)} ${rem(60)};
  background-color: #d8d8d8;

  ${({ isLoading }) =>
    isLoading &&
    css`
      display: none;
    `}
`;

const ModalBody = styled.div`
  background-color: #fff;
  pointer-events: inherit;
  width: 100%;
`;

const ModalContent = styled.div`
  padding: ${rem(30)};
`;

const Error = styled.div`
  font-weight: 500;
  color: ${color('brand.error')};
  text-align: center;
`;

const CloseButton = styled(ButtonBase)`
  position: absolute;
  top: ${rem(16)};
  right: ${rem(16)};
  cursor: pointer;

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: ${rem(-15)};
    right: ${rem(-15)};
    bottom: ${rem(-15)};
    left: ${rem(-15)};
  }

  svg {
    display: block;
  }
`;

const ImageBase = css`
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
`;

const PDF = styled.iframe`
  ${ImageBase};
  height: calc(100vh - 15rem);
  width: 100vw;
`;

const StyledAuthImage = styled(AuthImg)`
  ${ImageBase};
`;

export default ImageModal;
