/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import useLogout from 'auth/hooks/useLogout';

function LogoutLink({ className }) {
  const logout = useLogout();
  return (
    <div className={className}>
      <Link to="#" onClick={logout}>
        Logout
      </Link>
    </div>
  );
}

export default LogoutLink;
