import React from 'react';
import get from 'lodash/get';
import { Switch, Route, useHistory, useParams } from 'react-router-dom';
import WaitForExternalData from 'shared/components/WaitForExternalData/WaitForExternalData';
import ArrowNavButton from 'shared/components/ArrowNavButton/ArrowNavButton';
import { useGuideById } from 'guideProfile/hooks/queries';
import routes from 'shared/routes';
import TabNavigation from 'guide-details/components/TabNavigation/TabNavigation';
import Profile from 'guide-details/Profile/Profile';
import Information from 'guide-details/Information/Information';
import Algorithm from 'guide-details/Algorithm/Algorithm';
import Stats from 'guide-details/Stats/Stats';
import ActionForm from 'guide-details/ActionForm/ActionForm';
import Availability from './Availability';
import { useGuideStatus } from '../../guideProfile/hooks/useGuideStatus';
import {
  Layout,
  Main,
  ProfileName,
  ProfileStatus,
  TopContainer,
} from './styled';

function GuideDetailsScreen({ className }) {
  const getGuideStatus = useGuideStatus();
  const history = useHistory();
  const { guideId } = useParams();
  const guideQuery = useGuideById(guideId);
  const guide = get(guideQuery, 'data', {});
  const {
    active,
    approved,
    availabilities,
    application_status: guideStatus,
    code_of_conduct: coc,
    email: guideEmail,
    name: guideName,
  } = guide;

  const status = getGuideStatus(guide);

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <WaitForExternalData external={guideQuery}>
      <Layout className={className} showLogo={false}>
        <Main>
          <TopContainer>
            <div>
              <ArrowNavButton className="back-button" onClick={handleGoBack}>
                Back
              </ArrowNavButton>
              <ProfileName>
                <span role="img" aria-label="active">
                  {active ? `🟢` : `🔴`}&nbsp;&nbsp;
                </span>
                {guideName}
                <ProfileStatus>{status}</ProfileStatus>
              </ProfileName>
              <ActionForm
                active={active}
                approved={approved}
                guideName={guideName}
                guideId={guideId}
                guideStatus={guideStatus}
                guideEmail={guideEmail}
              />
            </div>
            <Availability availability={availabilities} coc={coc} />
          </TopContainer>

          <TabNavigation guideId={guideId} />
          <Switch>
            <Route path={routes.guideDetails.profile()} component={Profile} />
            <Route
              path={routes.guideDetails.information()}
              component={Information}
            />
            <Route
              path={routes.guideDetails.algorithm()}
              component={Algorithm}
            />
            <Route path={routes.guideDetails.stats()} component={Stats} />
          </Switch>
        </Main>
      </Layout>
    </WaitForExternalData>
  );
}

export default GuideDetailsScreen;
