import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useGuideById } from 'guideProfile/hooks/queries';

export const TOGGLE_STATUS = gql`
  mutation ToggleGuideStatus($guideId: ID!) {
    toggleGuideStatus(guideId: $guideId)
  }
`;

export default function useToggleGuideStatus(guideId) {
  const refetchGuide = useGuideById(guideId).refetch;
  const [toggleGuideStatus, toggleStatusResult] = useMutation(TOGGLE_STATUS);
  const toggleStatus = useCallback(
    async onSuccess => {
      try {
        await toggleGuideStatus({
          variables: { guideId },
        });

        refetchGuide().catch(() => {
          // Ignore
        });

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        //
      }
    },
    [toggleGuideStatus, refetchGuide, guideId]
  );

  return [toggleStatus, toggleStatusResult];
}
