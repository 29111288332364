import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function ExpandFieldButton({ onClick, children }) {
  return (
    <ExpandButton type=" button " onClick={onClick}>
      {children}
    </ExpandButton>
  );
}

const ExpandButton = styled.button`
  border: none;
  cursor: pointer;
  margin: 5px;
`;

ExpandFieldButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
