import React, { useCallback } from 'react';
import get from 'lodash/get';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { rem } from 'theme/lib';
import WaitForExternalData from 'shared/components/WaitForExternalData/WaitForExternalData';
import useGuides from 'guideProfile/hooks/useGuides';
import { statusLabelMap } from 'guideProfile/constants';
import useFilter from 'shared/hooks/useFilter/useFilter';
import routes from 'shared/routes';
import GuideList from 'guideProfile/components/GuideList/GuideList';
import {
  Layout,
  Main,
  StyledNavLink,
  ListSelector,
  InputWrapper,
  StyledInput,
  StyledSearchIcon,
} from './styled';
import { PhotoCell } from './Cells';

const FILTERED_GUIDES_BREAKPOINT = 100;

function dateFormatter(d) {
  return d ? format(new Date(d), 'yy/MM/dd') : '';
}

const statusFormatter = status => statusLabelMap[status] || status;
const NameCell = { key: 'name', label: 'Name', cellStyle: { fontWeight: 500 } };
const StatusCell = {
  key: 'status',
  label: 'Status',
  formatter: statusFormatter,
};
const baseFields = [
  NameCell,
  { key: 'updated_at', label: 'Last Updated', formatter: dateFormatter },
  StatusCell,
];
const LIST_FIELDS = {
  all: baseFields,
  active: baseFields,
  queue: baseFields,
  allFiltered: [
    {
      key: 'header_image',
      label: 'Photo',
      component: PhotoCell,
      headerStyle: { width: 100 },
      cellStyle: { paddingTop: `${rem(10)}` },
    },
    ...baseFields,
  ],
};

function GuideListScreen({ className }) {
  const location = useLocation();
  const showOnlyInQueue = location.pathname === routes.queue;
  const guidesQuery = useGuides(showOnlyInQueue);
  const guidesList = get(guidesQuery, 'data', []);
  const [filtered, setFilter] = useFilter(guidesList, [
    'name',
    'email',
    'mailing_address.city',
    { key: 'application_status', formatter: statusFormatter },
  ]);

  const handleChangeFilter = useCallback(e => setFilter(e.target.value), [
    setFilter,
  ]);

  let fields = LIST_FIELDS.all;
  if (filtered.length <= FILTERED_GUIDES_BREAKPOINT) {
    fields = LIST_FIELDS.allFiltered;
  }

  if (showOnlyInQueue) {
    fields = LIST_FIELDS.queue;
  }

  return (
    <WaitForExternalData external={guidesQuery}>
      <Layout className={className} showLogo={false}>
        <Main>
          <ListSelector>
            <StyledNavLink to={routes.all} activeClassName="is-active" replace>
              All
            </StyledNavLink>
            <StyledNavLink
              to={routes.active}
              activeClassName="is-active"
              replace
            >
              Active
            </StyledNavLink>
            <StyledNavLink
              to={routes.queue}
              activeClassName="is-active"
              replace
            >
              Queue
            </StyledNavLink>
          </ListSelector>
          <InputWrapper>
            <StyledInput
              placeholder="Search for Guides"
              onChange={handleChangeFilter}
            />
            <StyledSearchIcon />
          </InputWrapper>

          <GuideList data={filtered} fields={fields} />
        </Main>
      </Layout>
    </WaitForExternalData>
  );
}

export default GuideListScreen;
