import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HeaderLayout from 'shared/layouts/HeaderLayout/HeaderLayout';
import routes from 'shared/routes';
import LogoutLink from 'shared/components/LogoutLink/LogoutLink';
import GuideListScreen from '../GuideListScreen/GuideListScreen';
import GuideDetailsScreen from '../GuideDetailsScreen/GuideDetailsScreen';

const SwatScreen = () => {
  return (
    <HeaderLayout title="Guide Editor" renderActions={() => <LogoutLink />}>
      <Switch>
        <Route
          path={[routes.all, routes.queue, routes.active]}
          component={GuideListScreen}
        />
        <Route
          path={routes.guideDetails.base()}
          component={GuideDetailsScreen}
        />

        {/* Default route */}
        <Route path="/all" component={GuideListScreen} />
      </Switch>
    </HeaderLayout>
  );
};

export default SwatScreen;
