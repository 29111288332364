import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import routes from 'shared/routes';
import SignInScreen from 'screens/SignInScreen/SignInScreen';
import ErrorScreen from 'screens/ErrorScreen/ErrorScreen';
import SwatScreen from 'screens/SwatScreen/SwatScreen';
import ProtectedRoute from 'auth/components/ProtectedRoute/ProtectedRoute';

function App() {
  const { pathname } = useLocation();
  return (
    <Switch>
      {pathname === '/' && <Redirect to="/all" />}
      <Route path={routes.error} component={ErrorScreen} />
      <Route path={routes.login} component={SignInScreen} />
      <ProtectedRoute path="*" component={SwatScreen} />
    </Switch>
  );
}

const withRouter = WrappedComponent => ({ ...props }) => {
  /** can't use react-router-dom hooks in app component without first putting Router in the tree */
  return (
    <Router>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <WrappedComponent {...props} />
    </Router>
  );
};

export default withRouter(App);
