import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import CloudSelectorField from 'shared/components/formik/CloudSelectorField';
import { useProfileFieldOptions } from 'guideProfile/hooks/queries';
import SwatForm from 'shared/components/SwatForm/SwatForm';

const validationSchema = Yup.object().shape({
  communication_style: Yup.array()
    .min(1, 'Please select at least one')
    .max(3, 'Select a maximum of three'),
});

const initialValues = { communication_style: [] };

export default function CommunicationForm() {
  const fieldOptions = useProfileFieldOptions('communication_style').data;
  return (
    <SwatForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      label="Communication Style"
      marginVariant="noMargin"
    >
      <Field
        name="communication_style"
        size="small"
        component={CloudSelectorField}
        items={fieldOptions}
      />
    </SwatForm>
  );
}
