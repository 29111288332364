/* eslint-disable consistent-return */
import styled, { css } from 'styled-components/macro';
import { rem, color } from 'theme/lib';
import media from 'theme/media';

import { Field } from 'formik';

export const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${rem(50)} 0 0;
`;

export const PreviewContainer = styled.div`
  flex: 1 1 500px;
`;

export const PreviewBackground = styled.div`
  background: ${color('blacks.100')};
  border-radius: ${rem(40)};
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  flex: 1 1 60%;
  padding: 0 0 0 ${rem(50)};
`;

export const StyledField = styled(Field)`
  margin: 0 0 ${rem(10)};
`;

export const FormWrapper = styled.div`
  margin: 0 0 ${rem(60)};
  width: 100%;

  ${({ marginVariant }) => {
    if (marginVariant === 'noMargin') {
      return css`
        margin-bottom: 0;
      `;
    }
  }}
`;

export const Label = styled.label``;
export const Submit = styled.button`
  background: none;
  border: none;
`;
export const CertificationsContainer = styled.div`
  flex: 1 1 50%;
  padding-right: ${rem(30)};
  margin-bottom: ${rem(25)};
`;

export const ColumnGrid = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-right: ${rem(-30)};

  ${media.up('medium')} {
    flex-flow: row wrap;
  }
`;
