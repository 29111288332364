import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import OutlinedTextareaField from 'shared/components/formik/OutlinedTextareaField/OutlinedTextareaField';
import CharCountAdornment from 'shared/components/formik/CharCountAdornment/CharCountAdornment';
import { BIO_MAX_LENGTH } from 'guideProfile/constants';
import SwatForm from 'shared/components/SwatForm/SwatForm';

const validationSchema = Yup.object().shape({
  bio: Yup.string().required('Required'),
});

export default function AboutForm() {
  return (
    <SwatForm
      initialValues={{ bio: '' }}
      validationSchema={validationSchema}
      label="About"
    >
      <Field
        name="bio"
        component={OutlinedTextareaField}
        placeholder="Start typing here..."
        maxLength={BIO_MAX_LENGTH}
        maxRows={20}
        renderAdornmentEnd={({ value }) => (
          <CharCountAdornment value={value} limit={BIO_MAX_LENGTH} />
        )}
      />
    </SwatForm>
  );
}
