import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Button from 'shared/components/Button/Button';
import { breakpoints } from 'theme/media';
import { H3 } from 'shared/components/styled/typography';
import { useUpdateGuideRate, useReviewGuideRate } from './hooks';
import { StatsItem, Label } from './styled';

const { small, medium } = breakpoints;

const propTypes = {
  currentRate: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  guideId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const moneyFormatter = (v = 0, currency) => {
  return `$${v} ${currency}`;
};

const ReviewRate = ({ guideId, currentRate, currency, onClose }) => {
  const { newRate, loading } = useReviewGuideRate(guideId);
  const { updateRate, result } = useUpdateGuideRate();
  const formattedCurrentRate = currentRate.toFixed(2); // currentRate is guide.cost / 100 @ Stats screen
  const formattedNewRate = newRate && newRate.toFixed(2);
  const newRateAvailable = currentRate !== newRate;

  const update = useCallback(() => {
    updateRate(guideId, newRate, onClose);
  }, [guideId, onClose, updateRate, newRate]);

  if (result.loading || loading) return null;

  return (
    <Container newRateAvailable={newRateAvailable}>
      <H3>Review Guide Rate</H3>
      <div>
        <ItemContainer>
          <Label>Current Rate</Label>
          <StatsItem>
            {moneyFormatter(formattedCurrentRate, currency)}
          </StatsItem>
        </ItemContainer>
        <ItemContainer>
          {newRateAvailable ? (
            <>
              <Label>New Rate</Label>
              <StatsItem>
                {moneyFormatter(formattedNewRate, currency)}
              </StatsItem>
            </>
          ) : (
            <>
              <Label>No new rate is available at this time.</Label>
              <Button
                color="secondary"
                onClick={onClose}
                size="small"
                style={{ minWidth: 'unset', marginTop: 5 }}
              >
                Close
              </Button>
            </>
          )}
        </ItemContainer>
      </div>
      {newRateAvailable && (
        <Button
          onClick={update}
          shape="pill"
          disabled={result.loading}
          className="update-rate-button"
        >
          Save new rate
        </Button>
      )}
    </Container>
  );
};

ReviewRate.propTypes = propTypes;

export default ReviewRate;

const Container = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.offWhite};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 25%;
  padding: 2rem;
  width: auto;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;

    ${({ newRateAvailable }) =>
      newRateAvailable &&
      css`
        margin: 10px 0 30px;
      `}

    @media (min-width: ${medium}px) {
      flex-direction: row;
    }
  }

  .update-rate-button {
    @media (max-width: ${medium}px) {
      font-size: 16px;
      min-width: auto;
    }
  }

  @media (min-width: ${small}px) {
    justify-content: center;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
