import React, { useState } from 'react';
import TextField from 'shared/components/formik/TextField/TextField';
import SwatForm from 'shared/components/SwatForm/SwatForm';
import FieldArrayWrapper from './FieldArrayWrapper';

export default function WhatYouGetForm() {
  const [benefits, setBenefits] = useState(['', '', '']);

  const addBenefitsLine = () => {
    if (benefits.length >= 6) {
      window.alert(`Great benefits but please save some for the rest of us 💫`);
      return;
    }
    setBenefits(prev => [...prev, '']);
  };

  const removeBenefitsLine = () => {
    const copyBenefits = [...benefits].slice(0, -1);
    if (benefits.length <= 1) {
      window.alert(`Sorry you can't have less than 1 line 💫`);
      return;
    }
    setBenefits(copyBenefits);
  };

  return (
    <SwatForm
      initialValues={{ benefits }}
      label="What You'll Get"
      isExpandable
      onExpandableClick={addBenefitsLine}
      onRemoveLineClick={removeBenefitsLine}
    >
      <FieldArrayWrapper name="benefits" component={TextField} />
    </SwatForm>
  );
}
