import styled from 'styled-components/macro';
import { Form } from 'formik';
import { rem } from 'theme/lib';
import { H3 } from 'shared/components/styled/typography';
import SelectField from 'shared/components/formik/SelectField';
import OutlinedTextareaField from 'shared/components/formik/OutlinedTextareaField/OutlinedTextareaField';

export const StyledForm = styled(Form)`
  margin: ${rem(24)} 0 ${rem(60)} 0;
`;

export const FormLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const GuideField = styled(SelectField)`
  max-width: ${rem(200)};
  margin-bottom: ${rem(30)};
`;

export const Header = styled.div``;

export const Title = H3;

export const HelperText = styled.p`
  font-size: ${rem(13)};
  margin-top: ${rem(4)};
`;

export const ButtonToolbar = styled.div`
  display: flex;
  padding-top: ${rem(30)};

  > * {
    margin-right: ${rem(20)};
  }
`;

export const EmailTemplateField = styled(OutlinedTextareaField)`
  max-width: ${rem(540)};
  margin: ${rem(30)} 0 0 0;
`;

export const EditMessage = styled.p`
  font-weight: 500;
`;
