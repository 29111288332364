/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import PropTypes from 'prop-types';
import { AvailabilityContainer, ProfileName } from './styled';

const days = [
  `Monday`,
  `Tuesday`,
  `Wednesday`,
  `Thursday`,
  `Friday`,
  `Saturday`,
  `Sunday`,
].map(day => `${day}: `);

const formatTime = time => time.slice(0, 5);

const formatDay = (item, idx, self) => {
  /** check the last index. if the value of day is the same as the current iteration, set it to null */
  if (self[idx - 1] && item.day === self[idx - 1].day) {
    return { ...item, day: null };
  }
  return { ...item, day: days[item.day - 1] };
};

const Availability = ({ availability, coc }) => (
  <AvailabilityContainer>
    <table>
      {!availability.length ? (
        <thead>
          <tr>
            <ProfileName as="th" colspan="4">
              <span role="img">🔴&nbsp;&nbsp;</span> No availabilities have been
              set.
            </ProfileName>
          </tr>
        </thead>
      ) : (
        <>
          <thead>
            <tr>
              <ProfileName as="th" colspan="4">
                <span role="img">🟢&nbsp;&nbsp;</span>Availability:
              </ProfileName>
            </tr>
          </thead>
          <tbody>
            {availability
              .sort((a, b) => a.day - b.day)
              .map(formatDay)
              .map(a => (
                <tr key={a.id}>
                  <td>{a.day}</td>
                  <td>{formatTime(a.startTime)}</td>
                  <td>&nbsp;&nbsp;➡&nbsp;&nbsp;&nbsp;</td>
                  <td>{formatTime(a.endTime)}</td>
                </tr>
              ))}
          </tbody>
        </>
      )}
      {!coc && (
        <tfoot>
          <tr>
            <ProfileName
              style={{ margin: '10px 0 0 0' }}
              as="th"
              scope="row"
              colspan="4"
            >
              <span role="img" aria-label="coc">
                🔴&nbsp;&nbsp;Code of conduct not signed
              </span>
            </ProfileName>
          </tr>
        </tfoot>
      )}
    </table>
  </AvailabilityContainer>
);

Availability.propTypes = {
  availability: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.number.isRequired,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
    })
  ),
  coc: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Availability.defaultProps = {
  availability: [],
  coc: false,
};

export default Availability;
