import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useGuideById } from 'guideProfile/hooks/queries';

export const PERFORM_ACTION = gql`
  mutation PerformAction($id: ID!, $action: String!, $message: String!) {
    actionApplication(userID: $id, action: $action, message: $message)
  }
`;

export default function usePerformActionOnGuide(guideId) {
  const refetchGuide = useGuideById(guideId).refetch;
  const [mutate, queryHandler] = useMutation(PERFORM_ACTION);

  const performAction = useCallback(
    async (action, message, onSuccess) => {
      try {
        await mutate({
          variables: {
            id: guideId,
            action,
            message,
          },
        });

        // Refetch is placed here instead of in a refetch query because the refetch
        // queries throw an uncaught promise rejection if the request fails.
        refetchGuide().catch(() => {
          // Ignore
        });

        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        // Do nothing.
        // Error is contained in query handler.
      }
    },
    [guideId, mutate, refetchGuide]
  );

  return [performAction, queryHandler];
}
