import React from 'react';
import TextField from 'shared/components/formik/TextField/TextField';
import SwatForm from 'shared/components/SwatForm/SwatForm';
import { StyledField } from '../styled';

export default function NameForm() {
  return (
    <SwatForm
      initialValues={{ first_name: '', last_name: '', email: '' }}
      label="Name"
    >
      <StyledField name="first_name" component={TextField} />
      <StyledField name="last_name" component={TextField} />
      <StyledField name="email" component={TextField} />
    </SwatForm>
  );
}
