import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { color, rem } from 'theme/lib';
import { ReactComponent as IconUpload } from './upload.svg';
import { ReactComponent as IconDelete } from './delete.svg';

function IconFileControls({ className, onUpload, onDelete }) {
  return (
    <StyledIconFileControls className={className}>
      <FileControlButton type="button" onClick={onUpload}>
        <IconUpload />
        Upload
      </FileControlButton>
      {onDelete && (
        <FileControlButton type="button" onClick={onDelete}>
          <IconDelete />
          Delete
        </FileControlButton>
      )}
    </StyledIconFileControls>
  );
}

IconFileControls.propTypes = {
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

IconFileControls.defaultProps = {
  onDelete: null,
};

const FileControlButton = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: ${rem(13)};
  color: ${color('white')};
  text-align: center;

  > svg {
    display: block;
    margin: 0 auto ${rem(8)};
  }
`;

const StyledIconFileControls = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: auto;

  > * + * {
    margin-left: ${rem(32)};
  }
`;

export default IconFileControls;
