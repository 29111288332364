import { useState, useMemo, useRef } from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

export default function useFilter(
  data = [],
  fields = [],
  debounceWaitTime = 400
) {
  const [filter, setFilter] = useState('');
  const debouncedSetFilter = useRef(debounce(setFilter, debounceWaitTime))
    .current;

  const filtered = useMemo(
    () =>
      data.filter(r => {
        return fields.reduce((acc, field) => {
          // Already matched
          if (acc) {
            return true;
          }

          let value;
          switch (typeof field) {
            case 'object':
              value = get(r, field.key, '');
              if (field.formatter) {
                value = field.formatter(value) || '';
              }

              break;
            default:
              value = get(r, field, '');
          }

          return value.toUpperCase().includes(filter.toUpperCase());
        }, false);
      }),
    [data, fields, filter]
  );

  return [filtered, debouncedSetFilter];
}
