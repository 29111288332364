import React from 'react';
import { FieldArray } from 'formik';
import SwatForm from 'shared/components/SwatForm/SwatForm';
import ReferenceFields from './ReferenceFields';

const initialValues = {
  references: [
    {
      first_name: '',
      last_initial: '',
      num_sessions: '',
      phone: '',
      email: '',
    },
  ],
};

const ReferencesForm = () => {
  return (
    <SwatForm label="References" initialValues={initialValues}>
      <FieldArray
        name="references"
        render={({ form: { values } }) => (
          <>
            {values.references.map((item, index) => (
              <ReferenceFields
                key={`references-${index}`} // eslint-disable-line react/no-array-index-key
                name="references"
                index={index}
              />
            ))}
          </>
        )}
      />
    </SwatForm>
  );
};

export default ReferencesForm;
