import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SwatForm from 'shared/components/SwatForm/SwatForm';
import { rem, color } from 'theme/lib';
import useUpdateProfileImageField from 'guideProfile/hooks/useUpdateProfileImageField';
import FeaturePhotoField from '../photos/FeaturePhotoField';
import SecondaryPhotoField from '../photos/SecondaryPhotoField';

const Photos = ({ guideId }) => {
  const updateProfileImageField = useUpdateProfileImageField(guideId);
  return (
    <SwatForm
      initialValues={{
        header_image: '',
        hero_image: '',
      }}
      label="Photos"
      showSaveButton={false}
    >
      <Container>
        <PhotosItem>
          <FeaturePhotoField onFileUploaded={updateProfileImageField} />
          <PhotoLabel>Featured</PhotoLabel>
        </PhotosItem>
        <PhotosItem>
          <SecondaryPhotoField onFileUploaded={updateProfileImageField} />
          <PhotoLabel>Secondary</PhotoLabel>
        </PhotosItem>
      </Container>
    </SwatForm>
  );
};

const Container = styled.div`
  padding: ${rem(15)} ${rem(10)} ${rem(5)} ${rem(10)};
  border: solid 1px ${color('grays.200')};
  margin: 0 0 ${rem(60)};
  display: flex;
  align-items: flex-start;
`;

const PhotosItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 ${rem(10)} 0;
`;

const PhotoLabel = styled.span`
  display: block;
  margin: ${rem(5)} 0 ${rem(5)} 0;
  text-align: center;
`;

Photos.propTypes = {
  guideId: PropTypes.string,
};

Photos.defaultProps = {
  guideId: '',
};

export default Photos;
