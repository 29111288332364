import pick from 'lodash/pick';
import { ORDER_BY_STATUS } from 'guideProfile/constants';

export const currentUser = {
  __typename: 'User',
  id: '1678',
  name: 'Lyndon Sobolik',
  first_name: 'Lyndon',
  last_name: 'Sobolik',
  bio: null,
  image: null,
  email: 'lyndon@neon.cc',
  type: 'Seekers',
  jwt: null,
  dev_account: true,
  timezone: null,
  birthyear: '1994',
  communication_style: [],
  lifestyle: [],
  family_status: [],
  competencies: [],
  happiness_factor: null,
  isSubscribed: false,
  credits: 0,
  shareableCredits: 0,
  nextCreditDeposit: null,
  hasTrial: true,
  can_monitor: null,
  max_guides: 10,
  enabled: false,
  active: false,
  inTrial: false,
  phone_number: null,
  country: 'CA',
  code_of_conduct: false,
  resetpw: false,
  pastSessionsStats: null,
};

export const profile = {
  __typename: 'Guide',
  id: currentUser.id,
  name: 'Bob Bobby',
  email: 'bill@bill.com',
  first_name: 'Bob',
  last_name: 'Bobby',
  header_image: 'https://images.staging.sphereishere.com//1678.jpg',
  hero_image: 'https://images.staging.sphereishere.com/h1678.jpg',
  bio:
    'My journey as an executive coach was sparked by a desire to support leaders to live healthy, fulfilling, joyful lives while creating transformative change in the world. \n\nFrom grassroots community organizing to running a small business, from not-for-profit leadership to adventures in electoral politics, I focus always on empowering individuals to channel their strengths, energy, and talent toward the common good. \n\nI am known for recognizing the strengths in others, so they may see and build upon them. Together, we will co-create a learning partnership that allows you to slow down, reflect, experiment, and embody new ways of being.\n',
  fields: ['Leadership', 'Purpose', 'Wellbeing'],
  fullCerts: [
    {
      __typename: 'Cert',
      id: '43',
      name: 'Royal Roads: Graduate Certificate in Executive Coaching',
      url: 'https://i.picsum.photos/id/43/800/600.jpg',
    },
    {
      __typename: 'Cert',
      id: '44',
      name: 'Certified Dummy',
      url:
        'https://images-cdn.sphereishere.com//doc/1678-1579634731810-/home/node/public/1678.jpg',
    },
  ],
  experience: ['Community builder', 'Lifelong learner', 'Justice seeker'],
  allTestimonials: [
    {
      __typename: 'Testimonial',
      id: '123',
      first_name: 'Jon',
      last_initial: 'D',
      author: 'Jon D.',
      text:
        "I've been working with Bob for over three years, and he is the secret to my success as a business owner. Bob has a unique ability to see both the strategic '40,000 foot' level of a company and the tactical requirements for execution. Bob's experience as a business owner who has 'been there' make him a phenomenal coach.",
    },
  ],
  communication_style: ['Warm', 'Collaborative', 'Present'],
  family_status: ['partnership'],
  competencies: ['Career', 'Social', 'Intellectual', 'Health'],
  keyword: ['Business', 'Career'],
  approved: null,
  benefits: [
    'Create a learning partnership',
    'Experiment with small changes',
    'Embody new ways of being',
  ],
  impact: {
    __typename: 'Impact',
    seekers: 30,
    years: 25,
    // hours: 467,
  },
  references: [
    {
      __typename: 'Reference',
      first_name: 'Theodore',
      last_initial: 'M',
      num_sessions: 8,
      phone: '6045552222',
      email: 'theodore@gmail.com',
    },
    {
      __typename: 'Reference',
      first_name: 'Bill',
      last_initial: 'T',
      num_sessions: 5000,
      phone: '7785551111',
      email: 'bill@gmail.com',
    },
    {
      __typename: 'Reference',
      first_name: 'Joseph',
      last_initial: 'S',
      num_sessions: 1,
      phone: '6045551234',
      email: 'joey@gmail.com',
    },
  ],
  birthdate: '1974-09-22T00:00:00.000Z',
  practice: ['Full-time personal coach', 'HR Leader'],
  updated_at: '2019-12-22T19:55:58.354Z',
  phone: {
    __typename: 'Phone',
    mobile: '6045552222',
    work: '7785552222',
  },
  socials: {
    __typename: 'SocialMedia',
    facebook: null,
    linkedin: null,
    website: 'www.finance-coaching-expert.com',
    instagram: null,
  },
  interview_booked: false,
  mailing_address: {
    __typename: 'Address',
    address_1: '303 West Pender',
    address_2: 'Suite 300',
    city: 'Vancouver',
    stprv: 'BC',
    postal_code: 'V6B 1T3',
  },
  approach: null,
  other: {
    __typename: 'OtherFields',
    competencies: 'Medical Professionals',
    keywords: null,
  },
  application_status: 'not submitted',
  cost: 10000,
  country: 'CA',
  insights: {
    __typename: 'GuideInsights',
    matches: 4,
    completedMeets: 13,
    completedGuidanceSessions: 100,
    postSessionFeelingsSummary: [
      {
        __typename: 'FeelingSummary',
        feeling: 'Spectacular',
        count: 100,
      },
      {
        __typename: 'FeelingSummary',
        feeling: 'Mediocre',
        count: 7,
      },
      {
        __typename: 'FeelingSummary',
        feeling: 'Useless',
        count: 17,
      },
    ],
    upcomingSessions: 562,
    revenue: '100.00',
    nextPayment: '150.00',
    totalProfileViews: 123456789,
    currentMonthProfileViews: 3278918,
  },

  // image: 'https://images-cdn.sphereishere.com/a633.jpg',
  // education: null,
  // message:
  //  'Take your life and leadership to the next level through an empowering and strengths-based learning partnership.',
  // lifestyle: ['Executive/Senior Leader', 'Consultant'],
  // verified: null,
  // active: null,
  // enabled: null,
  // purpose:
  //   'To empower individuals to effectively channel their strengths, energy, and talent toward the common good.',
  // milestones: [
  //   'Transitioned my career from the corporate to nonprofit world in my early 30s',
  //   'Found meaningful work aligned with my purpose at the David Suzuki Foundation',
  //   'Joined an art studio and began to sell paintings after 30+ years of believing I wasn\'t a "creative person".',
  //   "Went back to school in my 40's to pursue a master's degree in Human Systems Intervention",
  //   'Decided not to have children of my own, and instead be a present, loving aunt to 10+ biological and chosen nieces and nephews',
  // ],
  // discovery: null,
  // birthyear: '1974',
  // email: 'lyndagerty@disabledgmail.com',
  // timezone: 'America/Vancouver',
};

export const mockProfile = (ext = {}, includeClientData = false) => {
  let clientFields = {};
  if (includeClientData) {
    clientFields = {
      birthdate: profile.birthdate.slice(0, 10),
    };
  }

  return {
    ...profile,
    ...ext,
    ...clientFields,
  };
};

export const mockUser = (ext = {}) => {
  return {
    ...currentUser,
    ...ext,
  };
};

export const mockGuides = (opts = { onlyInQueue: false, sorted: false }) => {
  const pickFields = (ext = {}) => {
    return {
      ...pick(profile, [
        'id',
        'name',
        'updated_at',
        'application_status',
        'email',
        'header_image',
        '__typename',
      ]),
      mailing_address: {
        ...pick(profile.mailing_address, ['city', '__typename']),
      },
      ...ext,
    };
  };

  const guides = [
    pickFields({
      id: 'id1',
      application_status: 'pending',
      updated_at: '2019-12-22T19:55:58.354Z',
    }),
    pickFields({
      id: 'id2',
      application_status: 'not submitted',
      updated_at: '2019-11-22T19:55:58.354Z',
    }),
    pickFields({
      id: 'id3',
      application_status: 'approved',
      updated_at: '2020-12-22T19:55:58.354Z',
    }),
  ];

  if (opts.sorted) {
    guides.sort((a, b) => {
      const sortByStatus =
        ORDER_BY_STATUS[a.application_status] -
        ORDER_BY_STATUS[b.application_status];
      if (sortByStatus !== 0) return sortByStatus;
      return new Date(b.updated_at) - new Date(a.updated_at);
    });
  }

  return opts.onlyInQueue
    ? guides.filter(g => g.application_status === 'pending')
    : guides;
};

export const mockGuide = (id, ext = {}) => {
  return mockProfile(
    {
      id,
      ...ext,
    },
    true
  );
};
