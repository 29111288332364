import React from 'react';
import FamilyForm from './forms/FamilyForm';
import KeywordForm from './forms/KeywordForm';
import CommunicationForm from './forms/CommunicationForm';
import CompetenciesForm from './forms/CompetenciesForm';
import LifestyleForm from './forms/LifestyleForm';
import WeightForm from './forms/WeightForm';
import DiversityForm from './forms/DiversityForm';
import { Layout } from './styled';

function Algorithm() {
  return (
    <Layout>
      <FamilyForm />
      <KeywordForm />
      <CommunicationForm />
      <CompetenciesForm />
      <LifestyleForm />
      <DiversityForm />
      <WeightForm />
    </Layout>
  );
}

Algorithm.propTypes = {};

export default Algorithm;
