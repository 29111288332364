import React from 'react';
import TextField from 'shared/components/formik/TextField/TextField';
import SwatForm from 'shared/components/SwatForm/SwatForm';
import FieldArrayWrapper from './FieldArrayWrapper';

export default function WhatYouGetForm() {
  return (
    <SwatForm initialValues={{ fields: ['', '', ''] }} label="Focus Fields">
      <FieldArrayWrapper name="fields" component={TextField} />
    </SwatForm>
  );
}
