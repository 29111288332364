export const STATUS_NOT_SUBMITTED = 'not submitted';
export const STATUS_SUBMITTED = 'submitted';
export const STATUS_UNDER_REVIEW = 'pending';
export const STATUS_APPROVED = 'approved';
export const STATUS_REJECTED = 'rejected';
export const STATUS_CANCELLED = 'cancelled';
export const STATUS_INACTIVE = 'inactive';
export const STATUS_ACTIVE = 'active';

export const statusLabelMap = {
  [STATUS_NOT_SUBMITTED]: 'Not Submitted',
  [STATUS_SUBMITTED]: 'Submitted',
  [STATUS_UNDER_REVIEW]: 'Under Review',
  [STATUS_APPROVED]: 'Ready',
  [STATUS_REJECTED]: 'Not a Fit',
  [STATUS_CANCELLED]: 'Cancelled',
  [STATUS_INACTIVE]: 'Inactive',
  [STATUS_ACTIVE]: 'Active',
};

// for sorting by application status
export const ORDER_BY_STATUS = {
  [statusLabelMap[STATUS_SUBMITTED]]: 0,
  [statusLabelMap[STATUS_UNDER_REVIEW]]: 1,
  [statusLabelMap[STATUS_APPROVED]]: 2,
  [statusLabelMap[STATUS_ACTIVE]]: 3,
  [statusLabelMap[STATUS_INACTIVE]]: 4,
  [statusLabelMap[STATUS_NOT_SUBMITTED]]: 5,
  [statusLabelMap[STATUS_CANCELLED]]: 6,
  [statusLabelMap[STATUS_REJECTED]]: 7,
};

export const UPLOAD_TYPE_PROFILE_PHOTO = 'profile';
export const UPLOAD_TYPE_SECONDARY_PHOTO = 'hero';
export const UPLOAD_TYPE_CERTIFICATION = 'cert';

export const BIO_MAX_LENGTH = 960;
export const CERT_NAME_MAX_LENGTH = 70;
export const APPROACH_MAX_LENGTH = 40;
export const EXPERIENCE_MAX_LENGTH = 120;
export const FOCUS_MAX_LENGTH = 60;
export const BENEFIT_MAX_LENGTH = 60;
export const TESTIMONIAL_MAX_LENGTH = 500;
