import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Button from 'shared/components/Button/Button';
import ErrorMessage from 'shared/components/ErrorMessage/ErrorMessage';
import {
  Header,
  Title,
  HelperText,
  ButtonToolbar,
  EditMessage,
  EmailTemplateField,
} from './styled';
import { emailTemplate, actionConfig } from './actionContent';

export default function ActionSelectedFields({
  guideName,
  guideEmail,
  action,
  handleCancel,
  isSubmitDisabled,
  error,
  message,
  setFieldValue,
}) {
  // Handle the action changing and updating the email template
  const actionRef = useRef(action);
  useEffect(() => {
    if (action !== actionRef.current) {
      if (message === emailTemplate(guideName, actionRef.current)) {
        // Message is still the default. We can change the value without losing changes
        setFieldValue('message', emailTemplate(guideName, action));
      }

      // Update ref
      actionRef.current = action;
    }
  }, [action, guideName, message, setFieldValue]);

  if (!action) {
    return null;
  }

  const { buttonText, hasMessage, title, helperText } = actionConfig(
    guideName,
    guideEmail,
    action
  );
  return (
    <>
      <Header>
        <Title>{title}</Title>
        <HelperText>{helperText}</HelperText>
      </Header>
      {hasMessage && (
        <>
          <EditMessage>Edit Message</EditMessage>
          <Field name="message" component={EmailTemplateField} />
        </>
      )}
      <ButtonToolbar>
        <Button variant="outlined" shape="pill" onClick={handleCancel}>
          Cancel
        </Button>
        <Button shape="pill" type="submit" disabled={isSubmitDisabled}>
          {buttonText}
        </Button>
      </ButtonToolbar>
      <ErrorMessage error={error} />
    </>
  );
}

ActionSelectedFields.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  guideName: PropTypes.string,
  guideEmail: PropTypes.string,
  action: PropTypes.string,
  message: PropTypes.string,
  isSubmitDisabled: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
};

ActionSelectedFields.defaultProps = {
  guideName: '',
  guideEmail: '',
  action: '',
  message: '',
  isSubmitDisabled: false,
  error: undefined,
};
