import styled from 'styled-components';
import { rem, color } from 'theme/lib';

export const StyledTable = styled.table`
  border-spacing: 0;
  width: 100%;
`;

export const TH = styled.th`
  border-bottom: solid ${rem(2)} ${color('grays.200')};
  padding: ${rem(20)} 0 ${rem(10)} 0;
  text-align: left;
`;

export const TD = styled.td`
  border-bottom: solid ${rem(2)} ${color('black')};
  padding: ${rem(20)} 0 ${rem(10)} 0;
  text-align: left;
`;

export const TR = styled.tr`
  cursor: pointer;
  :hover {
    background-color: ${color('offWhite')};
  }
`;

export const TRHEADER = styled.tr`
  /* pointer-events: none; */
`;
