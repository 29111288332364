import React from 'react';
import { Field } from 'formik';
import SwatForm from 'shared/components/SwatForm/SwatForm';
import { FieldLabel } from 'shared/components/formik/styled';
import FieldArrayWrapper from '../../Profile/forms/FieldArrayWrapper';
import { ColumnGrid, ColumnInputField } from '../styled';

const initialValues = {
  impact: { years: 0, seekers: 0 },
  practice: ['', '', '', '', '', ''],
  fullCerts: [],
};

const CoachExperienceForm = () => {
  return (
    <SwatForm label="Coach Experience" initialValues={initialValues}>
      <ColumnGrid>
        <Field
          name="impact.years"
          type="number"
          min={0}
          component={ColumnInputField}
          label="Years Coached"
          labelVariant="noMargin"
        />
        <Field
          name="impact.seekers"
          type="number"
          min={0}
          component={ColumnInputField}
          label="Clients Coached"
          labelVariant="noMargin"
        />
      </ColumnGrid>

      <FieldLabel>Coaching Career-to-Date</FieldLabel>
      <ColumnGrid>
        <FieldArrayWrapper name="practice" component={ColumnInputField} />
      </ColumnGrid>
    </SwatForm>
  );
};

export default CoachExperienceForm;
