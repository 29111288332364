import styled from 'styled-components';
import { color } from 'theme/lib';

export const Container = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  width: min-content;
`;

export const Toggle = styled.span`
  border-radius: ${({ size }) => size / 2}px;
  height: ${({ size }) => size}px;
  isolation: isolate;
  overflow: hidden;
  position: relative;
  width: ${({ size }) => size * 2}px;
  box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6,
    4px 4px 4px 0px #d1d9e6 inset, -4px -4px 4px 0px #ffffff inset;
`;

export const ToggleIndicator = styled.div`
  background: ${() => color('grays.100')};
  border-radius: ${({ size }) => size / 2}px;
  height: 100%;
  transform: translate3d(-75%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
  width: 200%;
  box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6;
`;

export const ToggleState = styled.input`
  display: none;

  &:checked ~ ${ToggleIndicator} {
    background: ${({ theme }) => theme.colors.brand.primary};
    opacity: 0.85;
    transform: translate3d(25%, 0, 0);
  }
`;
