/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'shared/routes';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import ArrowNavButton from 'shared/components/ArrowNavButton/ArrowNavButton';
import { H3 } from 'shared/components/styled/typography';
import LoginForm from 'auth/components/LoginForm/LoginForm';
import loginImg1x from './login.jpg';
import loginImg2x from './login@2x.jpg';
import loginImg3x from './login@3x.jpg';
import {
  SignInLayout,
  FormContainer,
  FormWrapper,
  ImageContainer,
  StyledImg,
} from './styled';

function SignInScreen({ className }) {
  const history = useHistory();
  const handleGoBack = () => {
    return history.push(routes.introBase);
  };
  return (
    <SplitLayout className={className}>
      <SplitLayout.Main>
        <SplitLayout.MainContent>
          <SignInLayout>
            <FormContainer>
              <FormWrapper>
                <H3>Sign in to your Admin account</H3>
                <LoginForm />
              </FormWrapper>
              <ArrowNavButton onClick={handleGoBack}>Go back</ArrowNavButton>
            </FormContainer>
            <ImageContainer>
              <StyledImg
                className={className}
                src={loginImg1x}
                srcSet={`${loginImg1x} 1x, ${loginImg2x} 2x, ${loginImg3x} 3x`}
                alt="Login"
              />
            </ImageContainer>
          </SignInLayout>
        </SplitLayout.MainContent>
      </SplitLayout.Main>
    </SplitLayout>
  );
}

SignInScreen.propTypes = {};
SignInScreen.defaultProps = {};

export default SignInScreen;
