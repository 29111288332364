import React from 'react';
import { useParams } from 'react-router-dom';
import ProfilePreview from 'shared/components/ProfilePreview/ProfilePreview';
import { useGuideById } from 'guideProfile/hooks/queries';
import {
  PhotosForm,
  NameForm,
  FocusFieldsForm,
  AboutForm,
  WhatYouGetForm,
  ExperienceForm,
  CertificationsForm,
  StyleForm,
  TestimonialsForm,
} from './forms';
import {
  Layout,
  PreviewContainer,
  ContentContainer,
  PreviewBackground,
} from './styled';

function Profile() {
  const { guideId } = useParams();
  const guideQuery = useGuideById(guideId);

  return (
    <Layout>
      <PreviewContainer>
        <PreviewBackground>
          <ProfilePreview profile={guideQuery.data} />
        </PreviewBackground>
      </PreviewContainer>
      <ContentContainer>
        <PhotosForm guideId={guideId} />
        <NameForm />
        <FocusFieldsForm />
        <AboutForm />
        <WhatYouGetForm />
        <ExperienceForm />
        <CertificationsForm />
        <StyleForm />
        <TestimonialsForm />
      </ContentContainer>
    </Layout>
  );
}

Profile.propTypes = {};

export default Profile;
