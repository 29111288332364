import styled from 'styled-components/macro';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import { rem } from 'theme/lib';
import media from 'theme/media';

export const Layout = styled(SplitLayout)`
  ${media.up('medium')} {
    padding-right: ${rem(47)};
  }
  ${media.up('huge')} {
    padding-left: ${rem(47)};
  }
`;

export const Main = styled(SplitLayout.Main)`
  margin-top: ${rem(24)};
  padding-top: ${rem(24)};
  padding-bottom: ${rem(100)};
`;

export const StatusContainer = styled.div`
  margin: ${rem(40)} 0;
`;

export const ProfileName = styled.div`
  margin-top: ${rem(40)};
  font-weight: 500;
  display: flex;
  align-items: center;
`;

export const ProfileStatus = styled.p`
  font-weight: normal;
  padding-left: ${rem(14)};
  padding-right: ${rem(14)};
  position: relative;
  margin: 0;
  text-transform: capitalize;

  &::before {
    display: block;
    content: '-';
    position: absolute;
    left: ${rem(4)};
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${media.up('medium')} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const AvailabilityContainer = styled.div`
  align-items: center;
  border: solid 1px hsl(0, 0%, 80%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: min-content;
  margin-bottom: 60px;
  margin-top: -40px;
  padding: 0 0 40px 0;
  width: 100%;

  ${media.up('medium')} {
    justify-content: center;
    margin: 15px 0 0 0;
    margin: 0;
    width: 50vw;
  }
`;
