/* eslint-disable no-param-reassign */
import { useMemo } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  STATUS_APPROVED,
  STATUS_CANCELLED,
  ORDER_BY_STATUS,
} from 'guideProfile/constants';
import { useGuideStatus } from './useGuideStatus';

export const GET_GUIDES = gql`
  query GetGuides {
    guides {
      id
      active
      approved
      header_image
      name
      email
      updated_at
      integrations {
        name
        enabled
      }
      application_status
      mailing_address {
        city
      }
    }
  }
`;

export default function useGuides(showOnlyInQueue = false) {
  const guidesQuery = useQuery(GET_GUIDES);
  const getGuideStatus = useGuideStatus();

  return useMemo(() => {
    let data = get(guidesQuery, 'data.guides');

    // Optionally filter out any guide that is not is the queue
    if (data && showOnlyInQueue) {
      data = data.filter(
        // we don't want to see guides who have cancelled their application or ones that are already approved
        ({ application_status: s }) =>
          [s !== STATUS_APPROVED, s !== STATUS_CANCELLED].every(Boolean)
      );
    }

    // Sort the data by:
    // - application status (asc): in order defined in ORDER_BY_STATUS
    // - updated_at date (desc): oldest at the bottom
    if (data) {
      data.map(guide => {
        guide.status = getGuideStatus(guide);
        return guide;
      });

      data.sort((a, b) => {
        const comparedByStatusResult =
          ORDER_BY_STATUS[a.status] - ORDER_BY_STATUS[b.status];

        if (comparedByStatusResult !== 0) {
          return comparedByStatusResult;
        }
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
    }
    return {
      ...guidesQuery,
      data,
    };
  }, [getGuideStatus, guidesQuery, showOnlyInQueue]);
}
