import {
  ACTION_SEND_EDITS,
  ACTION_REQUEST_NEW_PHOTO,
  ACTION_BOOK_INTERVIEW,
  ACTION_APPROVE_APPLICATION,
  ACTION_REJECT_APPLICATION,
  ACTION_SET_INACTIVE,
  ACTION_SET_UNAVAILABLE,
} from 'guide-details/constants';

export const allActions = [
  { code: ACTION_APPROVE_APPLICATION, name: 'Approve Submission' },
  { code: ACTION_REJECT_APPLICATION, name: 'Reject Application' },
  { code: ACTION_SEND_EDITS, name: 'Send Edits' },
  { code: ACTION_REQUEST_NEW_PHOTO, name: 'Request New Photo' },
  { code: ACTION_BOOK_INTERVIEW, name: 'Book Interview' },
  { code: ACTION_SET_INACTIVE, name: 'Set to Inactive' },
];

const pendingActions = [
  allActions.find(({ code }) => code === ACTION_SEND_EDITS),
  allActions.find(({ code }) => code === ACTION_REQUEST_NEW_PHOTO),
  allActions.find(({ code }) => code === ACTION_BOOK_INTERVIEW),
  allActions.find(({ code }) => code === ACTION_REJECT_APPLICATION),
  allActions.find(({ code }) => code === ACTION_APPROVE_APPLICATION),
];

const approvedActions = [
  allActions.find(({ code }) => code === ACTION_SET_INACTIVE),
  allActions.find(({ code }) => code === ACTION_SEND_EDITS),
  allActions.find(({ code }) => code === ACTION_REQUEST_NEW_PHOTO),
];

export const inactiveActions = [
  { code: ACTION_APPROVE_APPLICATION, name: 'Set to Active' },
];

export const rejectedActions = [
  { code: ACTION_BOOK_INTERVIEW, name: 'Reconsider' },
];

// Possible application_status values:
// "not submitted" - default state, has not completed application
// "submitted"     - Guide has submitted, but we have not reviewed and booked an interview yet
// "pending"       - Reviewed, interview booked, potential edits recommended, not approved yet
// "approved"      - Reviewed, interviewed, vetted, ready to be matched
// "cancelled"     - Guide cancelled their app, no further action
// "rejected"      - Guide not suitable at this time

export const availableActions = (active, guideStatus) => {
  switch (guideStatus) {
    case 'approved':
      return active ? approvedActions : inactiveActions;

    case 'submitted':
    case 'pending':
      return pendingActions;

    case 'rejected':
      return rejectedActions;

    case 'not submitted':
    case 'cancelled':
      return [];

    default:
      return allActions;
  }
};

export const actionConfig = (name, email, action) => {
  let config = {
    buttonText: 'Send',
    hasMessage: false,
    title: `Send message to ${name}`,
    helperText: `Sending to ${email}`,
  };

  switch (action) {
    case ACTION_BOOK_INTERVIEW: {
      config = {
        ...config,
        buttonText: 'Book',
        title: `Book an interview with ${name}`,
        helperText: `Doing so will send an email to ${email}, to start the booking process.`,
      };
      break;
    }

    case ACTION_SEND_EDITS:
      config = {
        ...config,
        buttonText: 'Request',
        title: 'Are you sure you want to request profile edits?',
        hasMessage: true,
      };
      break;

    case ACTION_REJECT_APPLICATION: {
      config = {
        ...config,
        title: 'Are you sure you want to reject this application?',
        helperText: `Doing so will send an email to ${email}, to inform them of the status of their application.`,
      };
      break;
    }

    case ACTION_REQUEST_NEW_PHOTO: {
      config = {
        ...config,
        hasMessage: true,
        buttonText: 'Request',
        title: 'Are you sure you want to request a new photo?',
      };
      break;
    }

    case ACTION_APPROVE_APPLICATION:
    case ACTION_SET_UNAVAILABLE:
    case ACTION_SET_INACTIVE: {
      config = {
        ...config,
        buttonText: 'Confirm',
        title: 'Are you sure you want to save this update?',
        helperText: "Doing so will change this guide's status.",
      };
      break;
    }

    default:
  }

  return config;
};

export const emailTemplate = (guideName, action) => {
  switch (action) {
    case ACTION_SEND_EDITS:
      return `Hi ${guideName},`;
    case ACTION_SET_UNAVAILABLE:
    case ACTION_SET_INACTIVE:
    case ACTION_REQUEST_NEW_PHOTO:
      // Set a message because the API requires one.
      return 'Empty message field';
    default:
      return '';
  }
};
