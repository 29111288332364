import styled from 'styled-components/macro';
import { rem, color } from 'theme/lib';

export const Layout = styled.div`
  padding: ${rem(50)} 0 0;
`;

export const StatsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const StatsItem = styled.div`
  flex: 0 0 18%;
  margin: 0 ${rem(10)} ${rem(28)} 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 2px solid ${color('blacks.100')};
  width: 100%;
  padding: 0.5rem 0;
  color: ${color('black')};
`;

export const Label = styled.label`
  display: block;
  line-height: ${18 / 16};
  margin-bottom: ${rem(8)};
`;

export const PostSessionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 40px;
`;

export const FeelingWrapper = styled.div`
  flex: 1 1 82%;
  margin: 0 2% 2% 0;
  margin-right: ${rem(10)};
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

export const CountWrapper = styled(FeelingWrapper)`
  flex: 1 1 15%;
`;

export const GuideRateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
