import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import usePerformActionOnGuide from 'guideProfile/hooks/usePerformActionOnGuide';
import useToggleGuideStatus from 'guideProfile/hooks/useToggleGuideStatus';
import ActionSelectedFields from './ActionSelectedFields';
import { StyledForm, FormLayout, GuideField } from './styled';
import { availableActions } from './actionContent';
import { ACTION_APPROVE_APPLICATION, ACTION_SET_INACTIVE } from '../constants';
import { STATUS_APPROVED } from '../../guideProfile/constants';

const validationSchema = Yup.object().shape({
  action: Yup.string(),
  message: Yup.string(),
});

const initialValues = {
  action: '',
  message: '',
};

function ActionForm({ active, guideName, guideId, guideStatus, guideEmail }) {
  const [performAction, { loading, error }] = usePerformActionOnGuide(guideId);
  const [toggleStatus, toggleStatusResult] = useToggleGuideStatus(guideId);

  const handleSubmit = useCallback(
    ({ action, message }, { resetForm }) => {
      switch (action) {
        case ACTION_APPROVE_APPLICATION:
          if (guideStatus !== STATUS_APPROVED) {
            performAction(ACTION_APPROVE_APPLICATION, message);
          }
          toggleStatus(() => {
            resetForm();
          });
          break;
        case ACTION_SET_INACTIVE:
          toggleStatus(() => {
            resetForm();
          });
          break;
        default:
          performAction(action, message, () => {
            resetForm();
          });
          break;
      }
    },
    [guideStatus, performAction, toggleStatus]
  );

  const actionOptions = availableActions(active, guideStatus);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, resetForm, isValid, setFieldValue }) => {
        const handleCancel = () => {
          resetForm();
        };

        const isSubmitDisabled =
          !isValid || loading || toggleStatusResult.loading;
        return (
          <StyledForm>
            <FormLayout>
              <Field
                name="action"
                component={GuideField}
                label="Choose an action"
                placeholderLabel="Action"
                options={actionOptions}
              />
              <ActionSelectedFields
                isSubmitDisabled={isSubmitDisabled}
                action={values.action}
                guideName={guideName}
                guideEmail={guideEmail}
                handleCancel={handleCancel}
                error={error || toggleStatusResult.error}
                setFieldValue={setFieldValue}
                message={values.message}
              />
            </FormLayout>
          </StyledForm>
        );
      }}
    </Formik>
  );
}

ActionForm.propTypes = {
  active: PropTypes.bool,
  guideId: PropTypes.string.isRequired,
  guideName: PropTypes.string,
  guideStatus: PropTypes.string,
  guideEmail: PropTypes.string,
};

ActionForm.defaultProps = {
  active: false,
  guideName: '',
  guideStatus: '',
  guideEmail: '',
};

export default ActionForm;
