import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import { Field } from 'formik';

export const FormWrapper = styled.div`
  margin: 0 0 ${rem(60)};
`;

export const FormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 0 0 ${rem(25)};
`;

export const Label = styled.label``;

export const Submit = styled.button`
  background: none;
  border: none;
  padding: 0;
`;

export const StyledField = styled(Field)`
  margin: 0 0 ${rem(10)};
`;
