import { rem } from 'theme/lib';
import styled from 'styled-components';
import media from 'theme/media';
import SelectField from 'shared/components/formik/SelectField';
import PhoneNumberField from 'shared/components/formik/PhoneNumberField/PhoneNumberField';
import TextField from 'shared/components/formik/TextField/TextField';

export const ColumnGrid = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-right: ${rem(-30)};

  ${media.up('medium')} {
    flex-flow: row wrap;
  }
`;

export const Layout = styled.div`
  padding: ${rem(50)} 0 0;
`;

export const ColumnInputField = styled(TextField)`
  flex: 1 2 50%;
  padding-right: ${rem(30)};
  margin-bottom: ${rem(25)};
`;

export const ColumnPhoneField = styled(PhoneNumberField)`
  flex: 1 1 50%;
  padding-right: ${rem(30)};
  margin-bottom: ${rem(25)};
`;

export const BirthdateInputField = styled(ColumnInputField)`
  max-width: 50%;
`;

export const ColumnInitialField = styled(ColumnInputField)`
  flex: 1 1 25%;
  padding-right: ${rem(30)};
  margin-bottom: ${rem(25)};
`;

export const ColumnSessionsField = styled(ColumnInputField)`
  flex: 1 2 25%;
  padding-right: ${rem(30)};
  margin-bottom: ${rem(25)};
`;

export const ColumnSelectField = styled(SelectField)`
  flex: 1 1 50%;
  padding-right: ${rem(30)};
  margin-bottom: ${rem(25)};

  label {
    margin: 0;
  }
`;

export const MailingAddressField = styled(TextField)`
  flex: 3 2 80%;
  margin-bottom: ${rem(25)};
`;

export const SuiteField = styled(MailingAddressField)`
  flex: 0 2 20%;
  margin-bottom: ${rem(25)};
`;

export const MailingAddressContainer = styled.div`
  flex: 0 0 50%;
  padding-right: ${rem(30)};
  display: flex;
`;

export const Submit = styled.button`
  background: none;
  border: none;
  padding: 0;
  align-self: flex-end;
`;
