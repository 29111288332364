import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Toggle, ToggleState, ToggleIndicator } from './styled';

const propTypes = {
  // dom id
  id: PropTypes.string.isRequired,
  // is the switch in the ON position?
  on: PropTypes.bool.isRequired,
  // toggle size in px
  size: PropTypes.number.isRequired,
  // on/off toggle
  toggle: PropTypes.func.isRequired,
};

const Switch = ({ id, on, toggle, size = 30 }) => {
  useEffect(() => {
    const checkbox = document.querySelector(`#switch-checkbox-${id}`);
    if (checkbox) {
      checkbox.checked = on;
    }
  }, [on, id]);

  return (
    <Container onClick={toggle}>
      <Toggle size={size}>
        <ToggleState
          id={`switch-checkbox-${id}`}
          name="check"
          type="checkbox"
          value="check"
        />
        <ToggleIndicator size={size} />
      </Toggle>
    </Container>
  );
};

Switch.propTypes = propTypes;

export default Switch;
