import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  ColumnGrid,
  ColumnInitialField,
  ColumnSessionsField,
  ColumnInputField,
} from '../styled';

function ReferenceFields({ name, index }) {
  const nameIndex = `${name}.${index}`;
  return (
    <>
      <ColumnGrid>
        <Field
          label="First Name"
          labelVariant="noMargin"
          name={`${nameIndex}.first_name`}
          type="text"
          component={ColumnInputField}
        />
        <Field
          label="Last Initial"
          labelVariant="noMargin"
          name={`${nameIndex}.last_initial`}
          type="text"
          component={ColumnInitialField}
          maxLength={1}
        />
        <Field
          label="# of Sessions"
          labelVariant="noMargin"
          name={`${nameIndex}.num_sessions`}
          type="number"
          component={ColumnSessionsField}
          min={0}
        />
      </ColumnGrid>
      <ColumnGrid>
        <Field
          label="Phone Number"
          labelVariant="noMargin"
          name={`${nameIndex}.phone`}
          type="text"
          component={ColumnInputField}
        />
        <Field
          label="Email Address"
          labelVariant="noMargin"
          name={`${nameIndex}.email`}
          type="text"
          component={ColumnInputField}
        />
      </ColumnGrid>
    </>
  );
}

ReferenceFields.propTypes = {
  /** FieldArray name. */
  name: PropTypes.string.isRequired,
  /** FieldArray index. */
  index: PropTypes.number.isRequired,
  /** Array index of the currently focused FieldArray item. */
};

export default ReferenceFields;
