import React from 'react';
import ContactForm from './forms/ContactForm';
import CoachExperienceForm from './forms/CoachExperienceForm';
import ReferencesForm from './forms/ReferencesForm';
import { Layout } from './styled';

function Information() {
  return (
    <Layout>
      <ContactForm />
      <CoachExperienceForm />
      <ReferencesForm />
    </Layout>
  );
}

Information.propTypes = {};

export default Information;
