/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { color } from 'theme/lib';

export function PhotoCell({ row }) {
  return (
    <Container>
      {row.header_image ? (
        <img src={row.header_image} alt={row.name} height="auto" width="100%" />
      ) : (
        <PlaceholderImg>
          <PlaceholderText>No image</PlaceholderText>
        </PlaceholderImg>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 132px;
  width: 82px;
  display: flex;
  align-items: center;
`;

const PlaceholderImg = styled.div`
  background-color: ${color('blacks.200')};
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;

const PlaceholderText = styled.h6`
  width: 100%;
  color: ${'white'};
`;
