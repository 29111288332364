import React from 'react';
import SwatForm from 'shared/components/SwatForm/SwatForm';
import { Field } from 'formik';
import Certifications from './Certifications';

import { CertificationsContainer } from '../styled';

export default function CertificationsForm() {
  return (
    <SwatForm
      initialValues={{
        fullCerts: [{ name: '', url: '' }],
      }}
      label="Training & Qualifications"
    >
      <CertificationsContainer>
        <Field name="fullCerts" component={Certifications} />
      </CertificationsContainer>
    </SwatForm>
  );
}
