import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { useFormikContext } from 'formik';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import useUploaderParams from 'guideProfile/hooks/useUploaderParams';
import { UPLOAD_TYPE_PROFILE_PHOTO } from 'guideProfile/constants';
import {
  handleChangeStatus,
  getFilesFromEvent,
} from 'guideProfile/components/Dropzone/helpers';
import DropzoneInput from 'guideProfile/components/Dropzone/Input';
import DropzoneLayout from 'guideProfile/components/Dropzone/Layout';
import DropzonePreview from 'guideProfile/components/Dropzone/Preview';

export default function FeaturePhotoField({ onFileUploaded }) {
  /* Id needs to be unique for SWAT */
  const idRef = useRef(uniqueId('feature-input-')).current;
  const getUploadParams = useUploaderParams(UPLOAD_TYPE_PROFILE_PHOTO);

  const { values } = useFormikContext();
  return (
    <Dropzone
      getUploadParams={getUploadParams}
      getFilesFromEvent={getFilesFromEvent}
      LayoutComponent={layoutProps => (
        <DropzoneLayout
          {...layoutProps} // eslint-disable-line react/jsx-props-no-spreading
          uploadedImage={values.header_image}
          dropzoneId={idRef}
        />
      )}
      InputComponent={inputProps => (
        <DropzoneInput
          {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
          title="Feature Photo"
          uploadedImage={values.header_image}
          dropzoneId={idRef}
        />
      )}
      PreviewComponent={DropzonePreview}
      onChangeStatus={handleChangeStatus({
        setFieldValue: onFileUploaded,
        fieldName: 'header_image',
      })}
      accept="image/png, image/jpeg"
      multiple={false}
    />
  );
}

FeaturePhotoField.propTypes = {
  onFileUploaded: PropTypes.func.isRequired,
};
