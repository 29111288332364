import React from 'react';
import { Field } from 'formik';
import CloudSelectorField from 'shared/components/formik/CloudSelectorField';
import { useProfileFieldOptions } from 'guideProfile/hooks/queries';
import SwatForm from 'shared/components/SwatForm/SwatForm';

const initialValues = { communication_style: [] };

export default function StyleForm() {
  const fieldOptions = useProfileFieldOptions('communication_style').data;
  return (
    <SwatForm initialValues={initialValues} label="Style & Approach">
      <Field
        name="communication_style"
        size="small"
        component={CloudSelectorField}
        items={fieldOptions}
      />
    </SwatForm>
  );
}
