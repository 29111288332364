import React from 'react';
import styled from 'styled-components';
import { rem } from 'theme/lib';
import { Field } from 'formik';
import * as Yup from 'yup';
import TextField from 'shared/components/formik/TextField/TextField';
import SwatForm from 'shared/components/SwatForm/SwatForm';

const TextComponent = styled(TextField)`
  flex: 1 1 50%;
  padding-right: ${rem(30)};
  margin-bottom: ${rem(25)};
`;

const validationSchema = Yup.object().shape({
  weight: Yup.number()
    .required(`Value required`)
    .integer()
    .positive()
    .min(0, 'Weight must be between 0-10')
    .max(10, 'Weight must be between 0-10')
    // this is a known bug in Yup. -0 and 0 are treated as ===. We do not like this. once an update to Yup is merged we will implement the fix in a better way
    // https://github.com/jquense/yup/issues/322#issuecomment-858041945
    .test({
      name: 'No Negative Zeros',
      message: `-0 cannot be used. Please use 0 and try again`,
      test: value => !Object.is(value, -0),
    }),
});

const initialValues = { weight: 1 };

const WeightForm = () => (
  <SwatForm
    initialValues={initialValues}
    validationSchema={validationSchema}
    label="Weight"
    marginVariant="noMargin"
  >
    <Field
      name="weight"
      type="text"
      component={TextComponent}
      label="Guide weight (0 - 10)"
      labelVariant="noMargin"
    />
  </SwatForm>
);
export default WeightForm;
